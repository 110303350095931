import React from 'react';
import {
    StyleSheet, View, ScrollView,
    TouchableOpacity,
    Image,
    Linking
} from 'react-native';
import { Theme } from '../styles';
import { InjectedFormProps, reduxForm, getFormSubmitErrors } from 'redux-form';
import { Button, Text, Toast } from 'native-base';
import { connect } from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { logout } from '../actions';
import { Dispatch } from 'redux';
import { getUser } from '../reducers';
import { State, User } from '../types';
import { NavigationScreenProp, NavigationScreenProps, NavigationStackScreenOptions } from 'react-navigation';
import images from '../assets';
import NavigationConstants from '../navigators/NavigationConstants';
import SettingsCard from '../components/SettingsCard';
import SettingsItem from '../components/SettingsItem';

export interface SettingsScreenProps {
    submitError: any,
    logout: any,
    user: User,
    navigation: NavigationScreenProp<any, any>
};

export interface SettingsForm {

};

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        backgroundColor: Theme.color.white,
    },
    menuRight: {
        marginRight: 10,
        alignSelf: 'center'
    },
    call: {
        marginTop: 25,
        marginLeft: 20,
        marginRight: 20,
        borderRadius: 4,
        backgroundColor: Theme.color.black,
        fontFamiliy: "Roboto"
    },
    logo: {
        alignSelf: 'center',
        width: 100,
        height: 100,
        marginTop: 20
    },
    infoContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Theme.color.black
    },
    infoText: {
        fontSize: 52,
        marginTop: 10,
        fontWeight: 'bold',
        color: Theme.color.white
    },
    emailContainer: {
        flexDirection: "row",
        marginTop: 30,
        marginBottom: 15
    },
    email: {
        fontSize: 16,
        fontWeight: 'bold',
        alignSelf: 'center',
        color: Theme.color.white
    },
    personal: {
        alignSelf: 'center',
        marginRight: 16
    },
    settingsItemContainer: {
        flexDirection: 'row'
    }

});

interface NavStateParams {
    onLogout: any
}

type NavigationOptionsFn<TParams=any> = (props: NavigationScreenProps<TParams>) => NavigationStackScreenOptions


class Settings extends React.Component<InjectedFormProps<SettingsForm, SettingsScreenProps> & SettingsScreenProps> {

    static navigationOptions: NavigationOptionsFn<NavStateParams> = ({ navigation, screenProps }) => ({
        titleStyle: {
            textAlign: 'left'
        },
        headerRight: (
            <TouchableOpacity onPress={() => navigation.state.params!!.onLogout()}>
                <Icon name='input' size={25} color='#fff' style={styles.menuRight} />
            </TouchableOpacity>
        )
    })

    componentWillReceiveProps(nextProps: SettingsScreenProps) {
        const { navigate } = this.props.navigation;
        if (this.props.submitting && Object.keys(nextProps.submitError).length == 0) {
            navigate(NavigationConstants.LOGIN, { isLogOut: true })
        } else if (Object.keys(nextProps.submitError).length > 0) {
            Toast.show({
                text: "Failed to logout",
                buttonText: "try again",
                duration: 30000,
                type: "danger",
            })
        }
    }

    componentWillMount() {
        const { logout } = this.props
        this.props.navigation.setParams({
            onLogout: () => logout(),
        });
    }

    render() {
        const { user } = this.props;
        const { navigate } = this.props.navigation;
        if (!user) {
            const { navigate } = this.props.navigation;
            navigate(NavigationConstants.LOGIN)
            return null
        }
        return (
            <ScrollView contentContainerStyle={[styles.container]}>
                <View style={styles.infoContainer}>
                    <Image
                        // @ts-ignore 
                        source={images.logo}
                        style={[styles.logo, { tintColor: Theme.color.white }]}
                    />
                    <Text style={styles.infoText}>{'VAPOX'}</Text>
                    <View style={styles.emailContainer}>
                        <Icon name={'person-outline'}
                            style={styles.personal}
                            color={Theme.color.white}
                            size={18}></Icon>
                        <Text style={styles.email}>{user.email}</Text>
                    </View>
                </View>
                <SettingsCard icon={'info'}
                    description={'Every time you purchase something in our store, make sure to let the sales person know that you have a loyalty account and mention your email above. You will receive 10 points for every £1 spent in store. '} />
                <View style={styles.settingsItemContainer}>
                    <SettingsItem onPress={() => Linking.openURL('https://www.google.com/maps/place/VAPOX+vape+%2B+coffee/@51.585815,-0.220168,15z/data=!4m5!3m4!1s0x0:0xc18c50e9a283b849!8m2!3d51.585815!4d-0.220168')}
                        icon={'access-time'} title={'WORKING HOURS'} />
                    <SettingsItem onPress={() => Linking.openURL(`tel:020 3897 0888`)}
                        icon={'phone'} title={'CALL US'} />
                </View>
                <View style={styles.settingsItemContainer}>
                    <SettingsItem onPress={() => Linking.openURL('https://www.google.com/maps/place/VAPOX+vape+%2B+coffee/@51.585815,-0.220168,15z/data=!4m5!3m4!1s0x0:0xc18c50e9a283b849!8m2!3d51.585815!4d-0.220168')} icon={'location-on'} title={'DIRECTIONS'} />
                    <SettingsItem onPress={() => Linking.openURL('https://www.vapox.co.uk')} icon={'shopping-basket'} title={'SHOP'} />
                </View>
            </ScrollView>
        );
    }
}

const mapStateToProps = (state: State) => {
    const user = getUser(state) || {}

    return {
        submitError: getFormSubmitErrors('Settings')(state),
        user: user,
        initialValues: {
            // @ts-ignore
            id: user.id,
            // @ts-ignore
            email: user.email,
            // @ts-ignore
            name: user.name,
            // @ts-ignore
            phone: user.phone
        }
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        logout: () => dispatch(logout()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(reduxForm<SettingsForm, SettingsScreenProps>({
    form: 'Settings',
})(Settings))