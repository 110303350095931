import { TypeKeys } from "../constants/TypeKeys";
import { Action } from "redux";
import { Wallet, Reward, User, Retail, RedeemState, PointHistory } from "../types";

export interface LoginAction extends Action {
    type: TypeKeys.LOGIN;
    email: string;
    url: string
}

export interface LoginStartAction extends Action {
    type: TypeKeys.LOGIN_START;
    email: string;
    url: string
}

export interface SendEmailAction extends Action {
    type: TypeKeys.SEND_EMAIL;
    email: string;
}

export interface ResendEmailAction extends Action {
    type: TypeKeys.RESEND_EMAIL;
    email: string;
}

export interface SendEmailFulfilledAction extends Action {
    type: TypeKeys.SEND_EMAIL_FULFILLED;
    email: string
}

export interface ResetPasswordAction extends Action {
    type: TypeKeys.RESET_PASSWORD;
    email: string;
}

export interface SyncAction extends Action {
    type: TypeKeys.SYNC;
}

export interface SaveUserAction extends Action {
    type: TypeKeys.SAVE_USER;
    id: string;
    name: string;
}

export interface SaveUserFulfilledAction extends Action {
    type: TypeKeys.SAVE_USER_FULFILLED,
    user: User
}

export interface SyncFulfilledAction extends Action {
    type: TypeKeys.SYNC_FULFILLED;
    user: User;
    retail: Retail
    rewards: Reward[],
    wallet: Wallet,
    redeems: RedeemState[],
    history: PointHistory[]
}

export interface RegisterAction extends Action {
    type: TypeKeys.REGISTER;
    email: string;
    password: string;
    name: string,
    phone: string,
    passwordConfirm: string
}
export interface LoginFulfilledAction extends Action {
    type: TypeKeys.LOGIN_FULFILLED;
    user: User;
    retail: Retail
    rewards: Reward[],
    wallet: Wallet,
    redeems: RedeemState[],
    history: PointHistory[]
}

export interface LoginFailedAction extends Action {
    type: TypeKeys.LOGIN_FAILED;
}

export interface RegisterFulfilledAction extends Action {
    type: TypeKeys.REGISTER_FULFILLED;
    user: User;
    retail: Retail
    rewards: Reward[],
    wallet: Wallet,
    redeems: RedeemState[],
    history: PointHistory[]
}

export interface ResetPasswordFulfilledAction extends Action {
    type: TypeKeys.RESET_PASSWORD_FULFILLED;
}

export interface OtherAction extends Action {
    type: TypeKeys.OTHER_ACTION;
}

export interface LogoutAction extends Action {
    type: TypeKeys.LOG_OUT;
}

export interface syncFailedAction extends Action {
    type: TypeKeys.SYNC_FAILED;
}

export interface LogoutFulfilledAction extends Action {
    type: TypeKeys.LOG_OUT_FULFILLED;
}
//Login
export const login = (email: string, url: string): LoginAction => ({
    type: TypeKeys.LOGIN,
    email,
    url
});
export const loginFulfilled = (user: User, rewards: Reward[],
    retail: Retail, wallet: Wallet, redeems: RedeemState[], history: PointHistory[]): LoginFulfilledAction => ({
        type: TypeKeys.LOGIN_FULFILLED,
        user,
        retail,
        rewards,
        wallet,
        redeems,
        history
    });

export const loginFailed = (): LoginFailedAction => ({
        type: TypeKeys.LOGIN_FAILED,
    });

export const loginStart = (email: string, url: string): LoginStartAction => ({
    type: TypeKeys.LOGIN_START,
    email,
    url
});

export const sendEmail = (email: string): SendEmailAction => ({
    type: TypeKeys.SEND_EMAIL,
    email,
});

export const resendEmail = (email: string): ResendEmailAction => ({
    type: TypeKeys.RESEND_EMAIL,
    email,
});

export const sendEmailFulfilled = (email: string): SendEmailFulfilledAction => ({
    type: TypeKeys.SEND_EMAIL_FULFILLED,
    email
});

export const logout = (): LogoutAction => ({
    type: TypeKeys.LOG_OUT
})

export const syncFailed = (): syncFailedAction => ({
    type: TypeKeys.SYNC_FAILED
})

export const logoutFulfilled = (): LogoutFulfilledAction => ({
    type: TypeKeys.LOG_OUT_FULFILLED
})

//register
export const register = (email: string, password: string, phone: string, name: string,
    passwordConfirm: string): RegisterAction => ({
        type: TypeKeys.REGISTER,
        email,
        password,
        phone,
        name,
        passwordConfirm
    });

export const registerFulfilled = (user: User, rewards: Reward[],
    retail: Retail, wallet: Wallet, redeems: RedeemState[], history: PointHistory[]): RegisterFulfilledAction => ({
        type: TypeKeys.REGISTER_FULFILLED,
        user,
        retail,
        rewards,
        wallet,
        redeems,
        history
    });

// resetpassword
export const resetPassword = (email: string): ResetPasswordAction => ({
    type: TypeKeys.RESET_PASSWORD,
    email
});

export const resetPasswordFulfilled = (): ResetPasswordFulfilledAction => ({
    type: TypeKeys.RESET_PASSWORD_FULFILLED,
});
//sync
export const sync = (): SyncAction => ({
    type: TypeKeys.SYNC,
});

export const syncFulfilled = (user: User, rewards: Reward[],
    retail: Retail, wallet: Wallet, redeems: RedeemState[], history: PointHistory[]): SyncFulfilledAction => ({
        type: TypeKeys.SYNC_FULFILLED,
        user,
        wallet,
        rewards,
        retail,
        redeems,
        history
    });
//user
export const saveUser = (id: string, name: string): SaveUserAction => ({
    type: TypeKeys.SAVE_USER,
    id,
    name
});

export const saveUserFulfilled = (user: User): SaveUserFulfilledAction => ({
    type: TypeKeys.SAVE_USER_FULFILLED,
    user
});


