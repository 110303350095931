import React from 'react';
import {
    StyleSheet,
    View,
    Text,
    TextInputProps,
    ViewStyle
} from 'react-native'

import { WrappedFieldProps } from 'redux-form';
import { GenericField, Field } from 'redux-form';
import { Input } from 'native-base';
import { Theme } from '../styles';

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'transparent',
        marginLeft: 30,
        marginRight: 30,
        marginTop: 15,
        borderRadius: 4,
        borderWidth: 3,
        borderColor: Theme.color.white,
    },
    input: {
        color: Theme.color.white,
        paddingLeft: 10,
        fontWeight: 'bold',
        fontFamily: 'roboto',
    },
    error: {
        color: "red",
        fontSize: 11,
        marginLeft: 30,
        marginRight: 30,
        marginTop: 2,
    },
    title: {
        color: Theme.color.white,
    },
    send: {
        width: 55,
        height: 55,
        borderRadius: 4,
        borderWidth: 2,
        backgroundColor: Theme.color.black
    }
})

interface Props {
    title: string,
    secureTextEntry: boolean,
    style: ViewStyle,
    isStackLabel: boolean,
    placeholderTextColor?: string,
    textStyle: ViewStyle,
    text?: string,
    isDirty: boolean,
    onDiscard: Function,
    onPress: Function
};

export const EditText: React.SFC<Props & WrappedFieldProps> = (props) => {
    const {
        input: { value, onChange },
        meta: { error, touched },
        ...inputProps // TS infers type here
    } = props;

    const { title, style, onPress, textStyle, } = props

    return (
        <View>
            <View style={[styles.container, style]}>
                <View style={{ flexDirection: 'row' }}>
                    <Input
                        onChangeText={onChange}
                        placeholder={title}
                        value={value}
                        placeholderTextColor={Theme.color.black54}
                        style={[styles.input, textStyle]}
                        {...inputProps} />
                </View>
            </View>
            {touched && error && <Text numberOfLines={3} style={styles.error}>{error}</Text>}
        </View>
    )
}

export default EditText
export const FormTextField = Field as new () => GenericField<TextInputProps>;