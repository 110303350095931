import React from 'react';
import { StyleSheet, View, Text, TouchableWithoutFeedback, Image } from 'react-native';
import { State, User, Redeem } from '../types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Theme } from '../styles';
import * as Animatable from 'react-native-animatable';
import { getUser } from '../reducers';
import images from '../assets';
import { Button } from 'native-base';

export interface RedeemInfromationProps {
    navigation: any,
    user: User,
    redeem: Redeem
};

const styles = StyleSheet.create({
    headerContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: Theme.color.black87
    },
    container: {
        width: '80%',
        borderRadius: 5,
        justifyContent: 'center',
        backgroundColor: Theme.color.white
    },
    cancel: {
        position: 'absolute',
        right: 0,
        top: 0,
        marginTop: 7,
        marginRight: 7
    },
    heaerImage: {
        marginTop: 50,
        alignSelf: 'center'
    },
    title: {
        fontSize: 16,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        color: Theme.color.black,
    },
    subTitle: {
        fontSize: 16,
        marginLeft: 5,
        marginRight: 5,
        marginTop: 10,
        alignSelf: 'center',
        textAlign: 'center',
        fontWeight: 'bold',
        color: Theme.color.black54,
    },
    code: {
        fontSize: 18,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 2,
        alignSelf: 'center',
        textAlign: 'left',
        fontWeight: 'bold',
        color: Theme.color.black,
    },
    dismissTitle: {
        marginLeft: 10,
        marginRight: 10,
        paddingRight: 50,
        paddingLeft: 50,
        alignSelf: 'center',
        fontWeight: 'bold',
        fontFamily: "Roboto",
        color: Theme.color.black,
        fontSize: 15,
    },
    dismissContainer: {
        height: 40,
        marginTop: 40,
        elevation: 0,
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
        backgroundColor: Theme.color.concreate,
    },
    codeContainer: {
        justifyContent: 'center',
        marginTop: 30,
    },
    icon: {
        alignSelf: 'center',
        marginTop: 10,
        width: 170,
        height: 170
    }
});

class InvitePopup extends React.Component<RedeemInfromationProps> {

    render() {
        const { navigation, user, redeem } = this.props
        return (
            <TouchableWithoutFeedback onPress={() => navigation.goBack()}>
                <View style={styles.headerContainer}>
                    <Animatable.View animation="bounceIn" style={styles.container}>
                        <View>
                            <Text style={styles.title}>{'Show this code in store in order to collect your item.'}</Text>
                            <View style={styles.codeContainer}>
                                <Text style={styles.subTitle}>{'CUSTOMER ID:'}</Text>
                                <Text style={styles.code}>{user.email}</Text>
                                <Image
                                    // @ts-ignore 
                                    source={images.qrcode} style={styles.icon} />
                            </View>
                            <Button full block
                                onPress={() => navigation.goBack()}
                                style={styles.dismissContainer}>
                                <Text style={styles.dismissTitle}>CLOSE</Text>
                            </Button>
                        </View>
                    </Animatable.View>
                </View>
            </TouchableWithoutFeedback>
        );
    }
}

const mapStateToProps = (state: State, ownProps: any) => {
    const redeem = ownProps.navigation.getParam('reward') || {}
    return {
        user: getUser(state) || {},
        redeem: redeem
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvitePopup)