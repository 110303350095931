import React from 'react'
import { createStackNavigator, createBottomTabNavigator, createSwitchNavigator } from 'react-navigation'
import Login from '../containers/Login';
import Home from '../containers/Home';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { Theme } from '../styles';
import SignUp from '../containers/SignUp';
import ResetPassword from '../containers/ResetPassword';
import Settings from '../containers/Settings';
import Start from '../containers/Start';
import PointsHistory from '../containers/PointsHistory';
import RedeemInformation from '../containers/RedeemInformation';
import { Easing, Animated } from 'react-native';
import Wallet from '../containers/Wallet';
import Account from '../containers/Account';
import PointsPopup from '../containers/PointsPopup';
import InvitePopup from '../containers/InvitePopup';
import Authrization from '../containers/Authrization';

const AuthNavigator = createStackNavigator({
  login: {
    screen: Login,
    navigationOptions: {
      header: null,
      headerBackTitle: null
    }
  },
  signup: {
    screen: SignUp
  },
  ResetPassword: {
    screen: ResetPassword
  },
  initialRouteName: "login",
});

const AuthPopupNavigator = createStackNavigator({
  auth: {
    screen: AuthNavigator,
  },
  authrization: {
    screen: Authrization,
    navigationOptions: {
      header: null
    }
  }
}, {
    initialRouteName: 'auth',
    transparentCard: true,
    mode: 'modal',
    headerMode: 'none',
    navigationOptions: {
      gesturesEnabled: false,
    },
    transitionConfig: () => ({
      transitionSpec: {
        duration: 300,
        easing: Easing.inOut(Easing.ease),
        sequence: Animated.sequence,
        timing: Animated.timing,
      },
      screenInterpolator: sceneProps => {
        const { position, scene } = sceneProps;
        const { index } = scene;

        const opacity = position.interpolate({
          inputRange: [index - 1, index],
          outputRange: [0, 1],
        });

        return { opacity };
      },
    }),

  });



const StartNavigator = createStackNavigator({
  Start: {
    screen: Start,
    navigationOptions: {
      header: null,
    }
  },
  initialRouteName: "Start",
});

const RedeemTab = createStackNavigator({
  view: {
    screen: Wallet,
    navigationOptions: {
      headerLeft: null,
      headerTitle: "Wallet"
    }
  }
})

const HomeTab = createStackNavigator({
  view: {
    screen: Home,
    navigationOptions: {
      headerLeft: null,
      headerTitle: "Home"
    }
  }
})

const AccountsTab = createStackNavigator({
  view: {
    screen: Settings,
    navigationOptions: {
      title: '',
      headerTintColor: Theme.color.white,
      headerStyle: {
        backgroundColor: Theme.color.black,
      },
      headerTitleStyle: {
        fontSize: 28,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    }
  }
})

const HomeNavigator = createBottomTabNavigator(
  {
    home: {
      screen: HomeTab,
      navigationOptions: {
        title: 'Home',
        tabBarIcon: ({ tintColor }) => (
          <Icon name="home" size={24} color={tintColor} style={{ marginTop: 8 }} />
        )
      }
    },
    wallet: {
      screen: RedeemTab,
      navigationOptions: {
        title: 'Wallet',
        tabBarIcon: ({ tintColor }) => (
          <Icon name="account-balance-wallet" size={24} color={tintColor} style={{ marginTop: 8 }} />
        ),
      }
    },
    accounts: {
      screen: AccountsTab,
      navigationOptions: {
        title: 'Account',
        tabBarIcon: ({ tintColor }) => (
          <Icon name="settings" size={24} color={tintColor} style={{ marginTop: 8 }} />
        )
      }
    },
  },
  {
    initialRouteName: "home",
    tabBarOptions: {
      activeTintColor: '#000',
      style: {
        height: 56,
      },
      labelStyle: {
        marginBottom: 8,
      },
      inactiveTintColor: Theme.color.black54,
    }
  },
)

const PointsNavigator = createStackNavigator({
  main: {
    screen: HomeNavigator,
    navigationOptions: {
      header: null,
      headerBackTitle: null
    }
  },
  history: {
    screen: PointsHistory,
  },
  account: {
    screen: Account,
    navigationOptions: {
      title: 'Account',
      headerTintColor: Theme.color.white,
      headerStyle: {
        backgroundColor: Theme.color.black,
      },
      headerTitleStyle: {
        fontSize: 28,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    }
  },
  initialRouteName: "main",
}, {

  })

const MainNavigator = createStackNavigator({
  dash: {
    screen: PointsNavigator,
    navigationOptions: {
      header: null,
    }
  },
  redeem: {
    screen: RedeemInformation,
    navigationOptions: {
      header: null
    }
  },
  PointsPopup: {
    screen: PointsPopup,
    navigationOptions: {
      header: null
    }
  },
  invite: {
    screen: InvitePopup,
    navigationOptions: {
      header: null
    }
  }
}, {
    initialRouteName: 'dash',
    transparentCard: true,
    mode: 'modal',
    headerMode: 'none',
    navigationOptions: {
      gesturesEnabled: false,
    },
    transitionConfig: () => ({
      transitionSpec: {
        duration: 300,
        easing: Easing.inOut(Easing.ease),
        sequence: Animated.sequence,
        timing: Animated.timing,
      },
      screenInterpolator: sceneProps => {
        const { position, scene } = sceneProps;
        const { index } = scene;

        const opacity = position.interpolate({
          inputRange: [index - 1, index],
          outputRange: [0, 1],
        });

        return { opacity };
      },
    }),

  })

const AppNavigator = createSwitchNavigator(
  {
    Start: StartNavigator,
    app: AuthPopupNavigator,
    root: MainNavigator,
  },
  {
    initialRouteName: "Start",
  },
)

export default AppNavigator