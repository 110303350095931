import React from 'react';
import {
    View,
    StyleSheet,
    Text,
    TouchableWithoutFeedback,
} from 'react-native'
import { Theme } from '../styles';
import { Card } from 'native-base';
import Icon from 'react-native-vector-icons/MaterialIcons';

interface Props {
    icon: string,
    title: string,
    onPress: () => void
}

class SettingsItem extends React.PureComponent<Props> {

    render() {
        const { icon, title, onPress } = this.props
        return (
            <TouchableWithoutFeedback onPress={onPress}>
                <View style={[styles.container]}>
                    <Card style={[{ borderRadius: 4, elevation: 0 }, styles.cardUsed]}>
                        <View style={styles.productContainer}>
                            <Icon name={icon}
                                style={{ alignSelf: 'center' }}
                                size={32} color={Theme.color.white} />
                            <Text style={styles.titleUsed}>{title}</Text>
                        </View>
                    </Card>
                </View>
            </TouchableWithoutFeedback>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        marginLeft: 10,
        marginRight: 10,
        marginTop: 15,
    },
    productContainer: {
        flex: 1,
        marginTop: 30,
        marginBottom: 15,
        justifyContent: 'center',
    },

    titleUsed: {
        fontSize: 14,
        fontWeight: 'bold',
        alignSelf: 'center',
        color: Theme.color.white,
        flexWrap: 'wrap',
        marginTop: 15
    },
    cardUsed: {
        backgroundColor: Theme.color.black
    },
})

export default SettingsItem