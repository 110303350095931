import { TypeKeys } from '../constants/TypeKeys';
import { stopSubmit, FormErrors, startSubmit } from 'redux-form';
import { mergeMap, switchMap, startWith, catchError } from 'rxjs/operators'
import { of, throwError } from 'rxjs'
import { Epic, ofType } from "redux-observable";
import { PointHistory } from '../types';
import { HistoryFetchAction, historyFetchFulfilled, logout } from '../actions';
import Api from './Api';

export const getHistory: Epic = (action$, store) => action$.pipe(
    ofType(TypeKeys.FETCH_HISTORY),
    mergeMap((action: HistoryFetchAction) => {
        return Api.purchaseHistory(action.id).pipe(
            switchMap((histories: PointHistory[]) => {
                return of(stopSubmit('PointsHistory')).pipe(
                    startWith(historyFetchFulfilled(histories))
                )
            }),
            catchError(error => {
                if (error.status == 401) {
                    return of(logout())
                }
                return throwError(action)
            }),
            catchError((error: FormErrors<FormData, any>) => {
                return of(stopSubmit('PointsHistory', error))
            })
        ).pipe(
            startWith(startSubmit('PointsHistory'))
        )
    }));