import React, { Dispatch } from 'react';
import { connect } from 'react-redux'
import { Keyboard, TouchableWithoutFeedback, StatusBar } from 'react-native'
import {
    StyleSheet, View,
    KeyboardAvoidingView,
    Platform,
} from 'react-native';
import {
    reduxForm,
    Field,
    getFormSubmitErrors,
    InjectedFormProps,
} from 'redux-form';
import EditText from '../components/EditText';
import { Button, Text, Toast } from 'native-base';
import { Theme } from '../styles';
import { email, isErrorEmpty } from '../utils/Validations';
import Base from '../styles/Base';
import Loader from '../components/Loader';
import { resetPassword } from '../actions';
import { NavigationScreenProp } from 'react-navigation';
import NavigationConstants from '../navigators/NavigationConstants';

export interface ResetPasswordScreenProps {
    resetPassword: any,
    submitError: any,
    navigation: NavigationScreenProp<any, any>
};

export interface ResetPasswordForm {
    email: string;
};

class ResetPassword extends React.Component<InjectedFormProps<ResetPasswordForm, ResetPasswordScreenProps> & ResetPasswordScreenProps> {

    static navigationOptions = {
        title: 'Reset Password',
        backButtonTitle: ' ',
        headerTintColor: Theme.color.black,
        titleStyle: {
            textAlign: 'left'
        },
    };

    componentWillReceiveProps(nextProps: ResetPasswordScreenProps) {
        const { submitting, navigation } = this.props
        const { navigate } = this.props.navigation;
        if (submitting && Object.keys(nextProps.submitError).length == 0) {
            Toast.show({
                text: "Check your email for the reset link",
                buttonText: "ok",
                type: "success",
                duration: 50000,
            })
        } else if (Object.keys(nextProps.submitError).length > 0) {
            Toast.show({
                text: "Failed to reset password",
                buttonText: "try again",
                duration: 30000,
                type: "danger",
            })
        }
    }

    _resetPassword = (values: ResetPasswordForm) => {
        const { resetPassword } = this.props
        resetPassword(values)
    }

    render() {
        const { handleSubmit, submitError, submitting } = this.props;
        return (
            <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
                <View style={styles.container}>
                <StatusBar barStyle={Platform.OS == 'ios' ? 'dark-content' : 'light-content'} />
                    <KeyboardAvoidingView enabled={Platform.OS == 'ios'} behavior={"position"}></KeyboardAvoidingView>
                    <Text style={styles.title}>{'Enter your email'}</Text>
                    <Field
                        name={'email'}
                        component={EditText}
                        title={"Email"}
                        placeholderTextColor={Theme.color.white}
                        validate={[email]}
                        secureTextEntry={false}
                    />
                    <Button full
                        onPress={handleSubmit(this._resetPassword)}
                        style={styles.resetpassword}>
                        <Text style={styles.resetpasswordText}>Send</Text>
                    </Button>
                    <Loader isVisible={submitting} />
                </View>
            </TouchableWithoutFeedback>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: 'black',
    },
    title: {
        fontSize: 22,
        alignSelf: 'center',
        color: Theme.color.white
    },
    fieldsContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    resetpassword: {
        borderRadius: 4,
        marginTop: 20,
        marginLeft: 30,
        marginRight: 30,
        backgroundColor: Theme.color.white,
    },
    resetpasswordText: {
        color: Theme.color.black,
        fontWeight:'bold',
        fontFamily: "Roboto"
    }
});

const mapStateToProps = (state: ResetPasswordForm) => {
    return {
        submitError: getFormSubmitErrors('ResetPassword')(state),
    }
}

const mapDispatchToProps = (dispatch: Dispatch<ResetPasswordForm>) => {
    return {
        resetPassword: (values: ResetPasswordForm) => dispatch(resetPassword(values.email))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm<ResetPasswordForm, ResetPasswordScreenProps>({
    form: 'ResetPassword'
})(ResetPassword))