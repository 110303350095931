import React from 'react';
import {
    StyleSheet,  
    ScrollView,
    Platform,
} from 'react-native';
import { Theme } from '../styles';
import { Field, InjectedFormProps, reduxForm, getFormSubmitErrors, reset, initialize } from 'redux-form';
import { email, notNull } from '../utils/Validations';
import Loader from '../components/Loader';
import { connect } from 'react-redux';
import { saveUser, logout } from '../actions';
import { Dispatch } from 'redux';
import { getUser } from '../reducers';
import { State, User } from '../types';
import FloatingEditText from '../components/FloatingEditText';
import user from '../reducers/user';
import NavigationConstants from '../navigators/NavigationConstants';
import { NavigationScreenProp } from 'react-navigation';

export interface AccountProps {
    save: any,
    user: User,
    submitError: any,
    logout: any,
    discard: any,
    initialize: any,
    navigation: NavigationScreenProp<any, any>
};

export interface AccountForm {
    id: string
    name: string;
    email: string;
    phone: string,
};

class Account extends React.Component<InjectedFormProps<AccountForm> & AccountProps> {

    static navigationOptions = {
        titleStyle: {
            textAlign: 'left'
        },
    };

    componentWillReceiveProps(nextProps: AccountProps) {

        const { submitting, initialize, user } = this.props
        if (submitting && Object.keys(nextProps.submitError).length == 0) {
            initialize({
                id: user.id,
                email: user.email,
                name: user.name,
                phone: user.phone
            })
        }
    }

    _save = (values: AccountForm) => {
        const { save } = this.props
        save(values)
    }

    render() {
        const { submitting, discard } = this.props;
        if (!user) {
            const { navigate } = this.props.navigation;
            navigate(NavigationConstants.LOGIN)
            return null
        }
        return (
            <ScrollView contentContainerStyle={[styles.container]} keyboardDismissMode={Platform.OS == 'ios' ? "on-drag" : "none"}>
                    <Field
                        name={'email'}
                        component={FloatingEditText}
                        title={"Email"}
                        validate={[notNull, email]}
                        style={styles.inputContainer}
                        textStyle={styles.input}
                        placeholderTextColor={Theme.color.black54}
                        onDiscard={discard}
                        editable={false}
                        secureTextEntry={false}
                    />
                <Loader isVisible={submitting} />
            </ScrollView>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        justifyContent: 'space-between',
        backgroundColor: Theme.color.white,
    },
    logoutIcon: {
        marginLeft: 15
    },
    logout: {
        marginBottom: 20,
        marginLeft: 10,
        marginRight: 10,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: Theme.color.black87,
        backgroundColor: Theme.color.white,
    },
    logoutText: {
        color: Theme.color.black87,
        fontSize: 16,
        fontWeight: 'bold',
    },
    input: {
        color: Theme.color.black87
    },
    inputContainer: {
        marginRight: 10
    },
    save: {
        marginTop: 10,
        marginLeft: 10,
        marginRight: 10,
        backgroundColor: Theme.color.black87,
    },
    saveText: {
        color: Theme.color.white,
        fontSize: 16,
        fontWeight: 'bold',
    },
});

const mapStateToProps = (state: State) => {
    const user = getUser(state) || {}

    return {
        submitError: getFormSubmitErrors('Account')(state),
        user: user,
        initialValues: {
            //@ts-ignore
            id: user.id,
            //@ts-ignore
            email: user.email,
            //@ts-ignore
            name: user.name,
            //@ts-ignore
            phone: user.phone
        }
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        save: (values: AccountForm) => dispatch(saveUser(values.id, values.name)),
        discard: () => dispatch(reset('Account')),
        initialize: (values: AccountForm) => dispatch(initialize("Account", values, false)),
        logout: () => dispatch(logout()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(reduxForm<AccountForm, AccountProps>({
    form: 'Account',
// @ts-ignore
})(Account))