
import { TypeKeys } from "../constants/TypeKeys";
import { ActionTypes } from "../actions";
import { Notification } from "../types";

const notification = (state: Notification = null!, action: ActionTypes): Notification => {
    switch (action.type) {
        case TypeKeys.FETCH_PUSH_NOTIFICATION_FULFILLED:
            const { token } = action
            return {
                token: token
            }
        case TypeKeys.LOG_OUT_FULFILLED:
            return null!
        default:
            return state
    }
}

export const getNotification = (state: Notification): Notification => state

export default notification
