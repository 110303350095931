import Parse from 'parse';

export class RedeemEntity extends Parse.Object {
    constructor() {
        super('Redeem')
    }

    get points() {
        return this.get('points') as number;
    }
    set points(value: number) {
        this.set('points', value);
    }

    get awarded() {
        return this.get('awarded') as boolean;
    }

    set awarded(value: boolean) {
        this.set('awarded', value);
    }
}

export class RewardEntity extends Parse.Object {
    constructor() {
        super('Reward')
    }

    get cost() {
        return this.get('cost') as number;
    }
    set cost(value: number) {
        this.set('cost', value);
    }

    get description() {
        return this.get('description') as string;
    }
    set description(value: string) {
        this.set('description', value);
    }

    get name() {
        return this.get('name') as string;
    }
    set name(value: string) {
        this.set('name', value);
    }

    get image() {
        return this.get('photo') as Parse.File;
    }
    set image(value: Parse.File) {
        this.set('photo', value);
    }
}

export class WalletEntity extends Parse.Object {
    constructor() {
        super('Wallet')
    }

    get points() {
        return this.get('points') as number;
    }
    set points(value: number) {
        this.set('points', value);
    }
}

export class RetailEntity extends Parse.Object {
    constructor() {
        super('Retail')
    }

    get appId() {
        return this.get('appId') as string;
    }
    set appId(value: string) {
        this.set('appId', value);
    }

    get name() {
        return this.get('name') as string;
    }
    set name(value: string) {
        this.set('name', value);
    }
}