const color = {
    white: '#ffffff',
    white50: 'rgba(255,255,255, 0.5)',
    white60: 'rgba(255,255,255, 0.6)',
    black: '#000000',
    black10: 'rgba(0,0,0,.10)',
    black30: 'rgba(0,0,0,.30)',
    black45: 'rgba(0,0,0,.45)',
    black80: 'rgba(0,0,0,.80)',
    black12: 'rgba(0,0,0,.12)',
    black87: 'rgba(0,0,0,.87)',
    black54: 'rgba(0,0,0,.54)',
    black60: 'rgba(0,0,0,.60)',
    nobel: '#B6B6B6',
    concreate: '#F3F3F3'
};

export default {
    color
}