import React from 'react';
import { StyleSheet, View, Text, TouchableWithoutFeedback } from 'react-native';
import { State, Reward } from '../types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Theme } from '../styles';
import * as Animatable from 'react-native-animatable';
import { Button, Toast } from 'native-base';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { InjectedFormProps, reduxForm, getFormSubmitErrors } from 'redux-form';
import { addRedeem } from '../actions';
import Loader from '../components/Loader';
import { NavigationScreenProp } from 'react-navigation';
import NavigationConstants from '../navigators/NavigationConstants';

export interface RedeemInfromationProps {
    submitError: any,
    addRedeem: any,
    reward: Reward,
    navigation: NavigationScreenProp<any, any>
};

export interface RedeemInfromationForm {
    navigation: any
};

const styles = StyleSheet.create({
    headerContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: Theme.color.black87
    },
    container: {
        width: '85%',
        borderRadius: 6,
        backgroundColor: Theme.color.white
    },
    image: {
        marginTop: 20,
        alignSelf: 'center'
    },
    title: {
        fontFamily: 'roboto',
        fontSize: 14,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 20,
        alignSelf: 'center',
        fontWeight: 'bold',
        textAlign: 'left',
        color: Theme.color.black54,
    },
    dismissTitle: {
        fontFamily: 'roboto',
        marginLeft: 10,
        marginRight: 10,
        paddingRight: 20,
        paddingLeft: 20,
        alignSelf: 'center',
        color: Theme.color.black,
        fontSize: 15,
    },
    redeemTitle: {
        fontFamily: 'roboto',
        marginLeft: 10,
        marginRight: 10,
        paddingRight: 20,
        paddingLeft: 20,
        alignSelf: 'center',
        color: Theme.color.white,
        fontSize: 15,
    },
    dismissContainer: {
        height: 40,
        marginTop: 20,
        marginBottom: 20,
        marginRight: 10,
        marginLeft: 20,
        alignSelf: 'center',
        backgroundColor: Theme.color.black10,
    },
    okContainer: {
        height: 40,
        marginTop: 20,
        marginBottom: 20,
        marginLeft: 10,
        marginRight: 20,
        alignSelf: 'center',
        backgroundColor: Theme.color.black87,
    },
    btnContainer: {
        flexDirection: 'row',
        alignSelf: 'center'
    }
});

class RedeemInformation extends React.Component<InjectedFormProps<RedeemInfromationForm, RedeemInfromationProps>
    & RedeemInfromationProps> {

    componentWillReceiveProps(nextProps: RedeemInfromationProps) {
        const { submitting, navigation } = this.props
        const { navigate } = this.props.navigation;
        if (submitting && Object.keys(nextProps.submitError).length == 0) {
            navigation.goBack()
            Toast.show({
                text: "Reward was redeemed succesfully. ",
                buttonText: "View",
                type: "success",
                duration: 50000,
                onClose: (reason) => {
                    reason === 'user' && navigate(NavigationConstants.Wallet)
                }
            })
        } else if (Object.keys(nextProps.submitError).length > 0) {
            navigation.goBack()
            Toast.show({
                text: "Something went wrong! ",
                buttonText: "Try again",
                duration: 30000,
                type: "danger",
            })
        }
    }

    render() {
        const { addRedeem, handleSubmit, reward, navigation, submitting, submitError } = this.props
        return (
            <TouchableWithoutFeedback onPress={() => navigation.goBack()}>
                <View style={styles.headerContainer}>
                    <Animatable.View animation="bounceIn" style={styles.container}>
                        <Icon name="add-shopping-cart"
                            style={styles.image}
                            size={60} color={Theme.color.black} />
                        <Text style={styles.title}>{`Are you sure you want spend ${reward.cost} points for ${reward.name} ?`}</Text>
                        <View style={styles.btnContainer}>
                            <Button iconRight light style={styles.dismissContainer}
                                onPress={() => navigation.goBack()}>
                                <Text style={styles.dismissTitle}>{'CANCEL'}</Text>
                            </Button>
                            <Button iconRight light style={styles.okContainer}
                                onPress={() => handleSubmit(addRedeem(reward))}>
                                <Text style={styles.redeemTitle}>{'REDEEM'}</Text>
                            </Button>
                        </View>
                        <Loader isVisible={submitting} />
                    </Animatable.View>
                </View>
            </TouchableWithoutFeedback>
        );
    }
}

const mapStateToProps = (state: State, ownProps: any) => {
    return {
        navigation: ownProps.navigation,
        submitError: getFormSubmitErrors('RedeemInformation')(state),
        reward: ownProps.navigation.state.params.reward
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addRedeem: (reward: Reward) => dispatch(addRedeem(reward)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm<RedeemInfromationForm, RedeemInfromationProps>({
    form: 'RedeemInformation'
})(RedeemInformation))