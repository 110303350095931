import { TypeKeys } from "../constants/TypeKeys";
import { Action } from "redux";
import { Wallet, Reward, RedeemState } from "../types";

export interface RedeemRewardAction extends Action {
    type: TypeKeys.ADD_REDEEM;
    reward: Reward;
}

export interface RewardRedeemFulfilledAction extends Action {
    type: TypeKeys.REWARD_REDEEM_FULFILLED,
    balance: number,
    redeem: RedeemState
}

export interface FetchRedeemAction extends Action {
    type: TypeKeys.FETCH_REDEEM;
}

export interface FetchRedeemFulfilledAction extends Action {
    type: TypeKeys.FETCH_REDEEM_FULFILLED,
    redeem: RedeemState[];
}

export const addRedeem = (reward: Reward): RedeemRewardAction => ({
    type: TypeKeys.ADD_REDEEM,
    reward
});
export const redeemRewardFulfilled = (redeem:RedeemState, balance: number): RewardRedeemFulfilledAction => ({
    type: TypeKeys.REWARD_REDEEM_FULFILLED,
    redeem,
    balance
});

export const getFetchRedeem = (): FetchRedeemAction => ({
    type: TypeKeys.FETCH_REDEEM,
});

export const fetchRedeemFulfilled = (redeem: RedeemState[]): FetchRedeemFulfilledAction => ({
    type: TypeKeys.FETCH_REDEEM_FULFILLED,
    redeem
});