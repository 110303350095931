import React from 'react';
import { connect } from 'react-redux'
import {
    StyleSheet, Image, ScrollView,
    KeyboardAvoidingView, Text,
    Platform,
    View,
    StatusBar,
} from 'react-native';
import {
    reduxForm,
    Field,
    formValueSelector,
    getFormSubmitErrors,
    InjectedFormProps,
} from 'redux-form';
import EditText from '../components/EditText';
import { Theme } from '../styles';
import images from '../assets';
import { NavigationScreenProp } from 'react-navigation';
import { email, isErrorEmpty } from '../utils/Validations';
import Base from '../styles/Base';
import { logoutFulfilled, sendEmail, loginStart } from '../actions';
import Loader from '../components/Loader';
import { Dispatch } from 'redux';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { State } from '../types';
import { Button } from 'native-base';
import { Keyboard } from 'react-native';
import NavigationConstants from '../navigators/NavigationConstants';
import { getUser } from '../reducers';

export interface LoginProps {
    sendEmail: any,
    submitError: any,
    logout: any,
    isLogOut: boolean,
    navigation: NavigationScreenProp<any, any>,
    email: string;
    url: string,
    login: any,
    isLogin: boolean
};
export interface LoginForm {
    email: string
    password: string,
};

class Login extends React.Component<InjectedFormProps<LoginForm, LoginProps> & LoginProps> {

    _sendEmail = (values: LoginForm) => {
        const { sendEmail } = this.props
        sendEmail(values)
        Keyboard.dismiss()
    }

    componentDidMount() {
        const { isLogOut } = this.props
        if (isLogOut) {
            this.props.logout()
        }
        //@ts-ignore
        const { url, handleSubmit, login, email, user } = this.props
        if (url != null) {
            handleSubmit(login(email, url))
        }

        const { navigate } = this.props.navigation;

        if (user) {
            navigate(NavigationConstants.HOME)
        }
    }

    componentWillReceiveProps(nextProps: LoginProps) {
        const { navigate } = this.props.navigation;
        const { submitting, email } = this.props
        if (submitting && Object.keys(nextProps.submitError).length == 0) {
            navigate(NavigationConstants.SIGNUP, { email: email })
        }
    }

    render() {
        // @ts-ignore
        const { handleSubmit, submitError, submitting } = this.props;
        return (
            <ScrollView contentContainerStyle={[styles.container]}
                keyboardDismissMode={Platform.OS == 'ios' ? "on-drag" : "none"}
                bounces={false}>
                <StatusBar barStyle="light-content" />
                <KeyboardAvoidingView enabled={Platform.OS == 'ios'} behavior={"position"}></KeyboardAvoidingView>
                <View>
                    <Image
                        //@ts-ignore
                        source={images.logoWithText} style={styles.logo} />
                </View>
                <View style={styles.fields}>
                    <View style={styles.titleContainer}>
                        <Text style={styles.title}>{'Please enter your email address below'}</Text>
                    </View>
                    <Field
                        name={'email'}
                        component={EditText}
                        title={"Email"}
                        placeholderTextColor={Theme.color.white50}
                        validate={[email]}
                        secureTextEntry={false}
                    />
                    <Text style={Base.error}>{!isErrorEmpty(submitError) && 'Failed to send email'}</Text>


                    <Button full
                        onPress={handleSubmit(this._sendEmail)}
                        style={styles.send}>
                        <Text style={styles.confirm}>Log In</Text>
                        <Icon name={'chevron-right'} size={24} color={Theme.color.white} style={styles.iconConfirm} />
                    </Button>
                </View>
                {submitting && <Loader isVisible={submitting} />}
            </ScrollView>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        justifyContent: 'space-between',
        backgroundColor: 'black',
    },
    vapox: {
        fontSize: 50,
        marginTop: 10,
        color: Theme.color.white,
        alignSelf: 'center',
    },
    fields: {
        backgroundColor: Theme.color.black,
        justifyContent: 'center'
    },
    confirm: {
        textAlign: 'center',
        color: Theme.color.white,
        fontSize: 18,
        marginLeft: 15,
        fontWeight: 'bold',
        fontFamily: "Roboto",

        paddingBottom: 4,
        paddingTop: 4
    },
    iconConfirm: {
        marginLeft: 15
    },
    send: {
        flex: 1,
        paddingLeft: 20,
        paddingRight: 20,
        marginLeft: 30,
        marginRight: 30,
        height: 54,
        marginTop: 10,
        marginBottom: 40,
        justifyContent: 'center',
        backgroundColor: 'rgba(255,255,255,0.12)',
        borderRadius: 4,
        borderColor: 'transparent',
        borderWidth: 2,
    },
    title: {
        fontFamily: 'roboto',
        textAlign: 'center',
        color: 'rgba(255,255,255,0.7)',
        fontSize: 16,
        fontWeight: 'bold'
    },
    titleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 30,
        marginTop: 25,
        marginRight: 30,
    },
    logo: {
        alignSelf: 'center',
        justifyContent: 'center',
        minWidth: 228,
        height: 174
    }
});

const mapStateToProps = (state: State, ownProps: any) => {
    const params = ownProps.navigation.state.params || false
    const emailSelector = formValueSelector('Login');
    return {
        isLogOut: params.isLogOut,
        url: ownProps.navigation.getParam('url') || null,
        //@ts-ignore
        email: emailSelector(state, 'email'),
        submitError: getFormSubmitErrors('Login')(state),
        user: getUser(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        sendEmail: (values: LoginForm) => dispatch(sendEmail(values.email)),
        logout: () => dispatch(logoutFulfilled()),
        login: (email: string, url: string) => dispatch(loginStart(email, url))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(reduxForm<LoginForm, LoginProps>({
    form: 'Login'
})(Login))