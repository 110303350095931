import React from 'react';
import {
    StyleSheet,
    View,
    Modal,
} from 'react-native'
import { Theme } from '../styles';
var Indicator = require('react-spinkit');

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Theme.color.black80
    }
})

interface Props {
    isVisible: boolean
}

class Loader extends React.PureComponent<Props> {

    render() {
        const { isVisible } = this.props
        return (
            <View style={isVisible && styles.container}>
                {isVisible && <Indicator
                    color={Theme.color.white}
                    size={80}
                    name={'folding-cube'} />}
            </View>
        )
    }
}

export default Loader