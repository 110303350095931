import { Retail } from "../types";
import { TypeKeys } from "../constants/TypeKeys";
import { AnyAction } from "redux";

const retal = (state: Retail = null!, action: AnyAction): Retail => {
    switch (action.type) {
        case TypeKeys.REGISTER_FULFILLED:
        case TypeKeys.LOGIN_FULFILLED:
            const { retail } = action
            return {
                id: retail.id,
                name: retail.name,
                appId: retail.appId
            }
        case TypeKeys.LOG_OUT_FULFILLED:
            return null!
        default:
            return state
    }
}

export const getRetail = (state: Retail): Retail => state

export default retal