import React from 'react';
import {
    StyleSheet,
    View,
    Text,
} from 'react-native'
import { Theme } from '../styles';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Theme.color.black45
    },
    welcomeContainer: {
        paddingTop: 150,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Theme.color.white
    },
    welcomeText: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: 35,
        fontSize: 20,
        color: Theme.color.black54,
        textAlign: 'center'
    }
})

interface Props {
    text: string,
    icon: string
}

class ListEmpty extends React.PureComponent<Props> {

    render() {
        const { text, icon } = this.props
        return (
            <View style={styles.welcomeContainer}>
                <MaterialIcons name={icon} color={Theme.color.black} size={128}></MaterialIcons>
                <Text style={styles.welcomeText}>{text}</Text>
            </View>
        )
    }
}

export default ListEmpty