import { connect } from 'react-redux'
import React from 'react';
import {
    StyleSheet,
    View
} from 'react-native'
import { Theme } from '../styles';
import { User, State } from '../types';
import { sync } from '../actions';
import { getUser } from '../reducers';
import { NavigationScreenProp } from 'react-navigation';
import NavigationConstants from '../navigators/NavigationConstants';
import { Dispatch } from 'redux';
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Theme.color.black,
    }
});

export interface StartScreenProps {
    sync: any
    user: User
    navigation: NavigationScreenProp<any, any>
};

class Start extends React.Component<StartScreenProps> {
    componentDidMount() {
        const { navigate } = this.props.navigation;
        const { user, sync } = this.props
        if(user) {
            navigate(NavigationConstants.HOME)
            sync()
        }else {
            navigate(NavigationConstants.APP)
        }
    };

    render() {
        return <View style={styles.container}/>
    }
}

const mapStateToProps = (state: State) => {
    return {
        user: getUser(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        sync: () => dispatch(sync())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Start)