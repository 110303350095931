
import { TypeKeys } from "../constants/TypeKeys";
import { ActionTypes } from "../actions";
import { User, State } from "../types";

const user = (state: User = null!, action: ActionTypes): User => {
  switch (action.type) {
    case TypeKeys.REGISTER_FULFILLED:
    case TypeKeys.SAVE_USER_FULFILLED:
    case TypeKeys.SYNC_FULFILLED:
    case TypeKeys.LOGIN_FULFILLED:
      const { user } = action
      return {
        id: user.id,
        email: user.email,
        phone: user.phone,
        name: user.name,
      }
    case TypeKeys.LOG_OUT_FULFILLED:
      return null!
    default:
      return state
  }
}


export const getUser = (state: State): User => state.user

export default user
