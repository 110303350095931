import React from 'react';
import {
    StyleSheet,
    View,
    Text,
    TextInputProps,
    ViewStyle
} from 'react-native'

import { WrappedFieldProps } from 'redux-form';
import { GenericField, Field } from 'redux-form';
import { Label, Item, Form, Input, Icon } from 'native-base';
import { Theme } from '../styles';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        marginTop: 15,
        marginBottom: 5
    },
    error: {
        color: "red",
        fontSize: 11,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 3,
        marginBottom: 3
    },
    item: {
        alignSelf: 'center',
    },
    title: {
        color: Theme.color.white,
    },
    icon: {
        fontSize: 38,
        alignSelf: 'center',
        marginLeft: 20,
        marginRight: 20
    },
    details: {
        color: Theme.color.black,
    }
})

interface Props {
    title: string,
    secureTextEntry: boolean,
    style: ViewStyle,
    isStackLabel: boolean,
    placeholderTextColor?: string,
    textStyle: ViewStyle,
    text?: string,
    isDirty: boolean,
    onDiscard: Function,
    editable: boolean,
    icon: string
};

export const FloatingEditText: React.SFC<Props & WrappedFieldProps> = (props) => {
    const {
        input: { value, onChange },
        meta: { error, invalid, touched, dirty, },
        ...inputProps // TS infers type here
    } = props;

    const { title, style, editable, onDiscard, placeholderTextColor, textStyle, icon } = props

    return (
        <View>
            <Form style={style}>
                <View style={styles.container}>
                    <Icon name={icon} style={styles.icon} color={Theme.color.black} />
                    <View style={styles.item}>
                        <Label>{title}</Label>
                        <Text
                            style={[styles.details, { color: placeholderTextColor }]}>{value}</Text>
                    </View>
                    {/* {dirty && <Icon name='close-circle' style={styles.icon} color={Theme.color.black} onPress={() => onDiscard()} />} */}
                </View>
            </Form>
            {touched && error && <Text numberOfLines={3} style={styles.error}>{error}</Text>}
        </View>
    )
}

export default FloatingEditText
export const FormTextField = Field as new () => GenericField<TextInputProps>;