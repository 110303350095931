import React from 'react';
import { StyleSheet, View, Text, TouchableWithoutFeedback } from 'react-native';
import { State, Reward } from '../types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Theme } from '../styles';
import * as Animatable from 'react-native-animatable';
import { Button } from 'native-base';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { addWallet } from '../actions';

export interface RedeemInfromationProps {
    navigation: any,
    points: number,
    balance: number,
    addWallet: any
};

export interface IState {
    layout: {
    }
}

const styles = StyleSheet.create({
    headerContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: Theme.color.black87
    },
    container: {
        width: '85%',
        borderRadius: 6,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Theme.color.white
    },
    image: {
        color: Theme.color.black,
        marginTop: 30,
        alignSelf: 'center'
    },
    title: {
        fontFamily: 'roboto',
        fontSize: 35,
        marginLeft: 5,
        marginRight: 5,
        alignSelf: 'center',
        textAlign: 'center',
        fontWeight: 'bold',
        color: Theme.color.black87,
        padding: 10,
    },
    subTitle: {
        fontFamily: 'roboto',
        fontSize: 18,
        marginLeft: 5,
        marginRight: 5,
        marginTop: 20,
        alignSelf: 'center',
        textAlign: 'left',
        color: Theme.color.black,
    },
    dismissTitle: {
        fontFamily: 'roboto',
        marginLeft: 10,
        marginRight: 10,
        paddingRight: 50,
        paddingLeft: 50,
        alignSelf: 'center',
        color: Theme.color.white,
        fontSize: 15,
    },
    dismissContainer: {
        height: 40,
        marginTop: 20,
        marginBottom: 20,
        alignSelf: 'center',
        backgroundColor: Theme.color.black87,
    },
    circle: {
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
        borderWidth: 2,
        borderColor: Theme.color.black87,
        backgroundColor: 'transparent'
    }
});

class PointsPopup extends React.Component<RedeemInfromationProps, IState> {

    componentDidMount() {
        const { balance } = this.props
        if (balance != null) {
            this.props.addWallet(balance)
        }
    };

    state = {
        layout: {

        }
    };

    onLayout(event: any) {
        const { height, width } = event.nativeEvent.layout;
        const newBorderRadius = width / 2
        const newLayout = {
            height: width,
            width: width,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: newBorderRadius,

        };

        this.setState({ layout: newLayout });
    }


    render() {
        const { navigation, points } = this.props
       // const AnimatedIcon = Animatable.createAnimatableComponent(Icon);
        return (
            <TouchableWithoutFeedback onPress={() => navigation.goBack()}>
                <View style={styles.headerContainer}>
                    <Animatable.View
                        animation="bounceIn"
                        iterationCount="infinite"
                        style={styles.container}>
                        <Icon name="star"
                            style={styles.image}
                            size={60} />
                        <View
                            onLayout={(event) => { this.onLayout(event) }}
                            style={[styles.circle, this.state.layout]}>
                            <Text
                                style={[styles.title]}>{`${points}`}</Text>
                        </View>
                        <Text style={styles.subTitle}>{`You received new VAPOX points!`}</Text>
                        <Button iconRight light style={styles.dismissContainer}
                            onPress={() => navigation.goBack()}>
                            <Text style={styles.dismissTitle}>{'CLOSE'}</Text>
                        </Button>
                    </Animatable.View>
                </View>
            </TouchableWithoutFeedback >
        );
    }
}

const mapStateToProps = (state: State, ownProps: any) => {
    const points = ownProps.navigation.getParam('points') || ''
    const balance = ownProps.navigation.getParam('balance') || ''
    return {
        navigation: ownProps.navigation,
        points: points,
        balance: balance
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addWallet: (balance: number) => dispatch(addWallet(balance))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PointsPopup)