import { redeemRewardFulfilled, RedeemRewardAction, fetchRedeemFulfilled, logout } from '../actions';
import { TypeKeys } from '../constants/TypeKeys';
import { stopSubmit, FormErrors, startSubmit } from 'redux-form';
import { mergeMap, switchMap, startWith, catchError } from 'rxjs/operators'
import { of, zip, throwError } from 'rxjs'
import { Epic, ofType } from "redux-observable";
import { getWallet, getUser, getReward } from '../reducers';
import { RedeemState } from '../types';
import Api from './Api';

export const redeemReward: Epic = (action$, store) => action$.pipe(
    ofType(TypeKeys.ADD_REDEEM),
    mergeMap((action: RedeemRewardAction) => {
        const reward = getReward(store.value, action.reward.id);
        const user = getUser(store.value)
        const wallet = getWallet(store.value)

        return zip(Api.redeem(user.id, reward!!.id, reward!!.cost),
            Api.purchase(wallet.id, reward!!.cost)).pipe(
                switchMap(redeemBalance => {
                    const redeemId = redeemBalance[0] as string
                    const points = redeemBalance[1] as number
                    const createdAt = new Date()
                    return of(stopSubmit('RedeemInformation'))
                        .pipe(
                            startWith(redeemRewardFulfilled(
                                {
                                    id: redeemId,
                                    cost: reward!.cost,
                                    rewardId: reward!.id,
                                    awarded: false,
                                    createdAt: createdAt
                                },
                                points
                            ))
                        )
                }),
                catchError(error => {
                    if (error.status == 401) {
                        return of(logout())
                    }
                    return throwError(action)
                }),
                catchError((error: Error) => of(stopSubmit('RedeemInformation', error as FormErrors<FormData, any>))))
            .pipe(startWith(startSubmit('RedeemInformation')))
    })
);

export const getRedeem: Epic = (action$, store) => action$.pipe(
    ofType(TypeKeys.FETCH_REDEEM),
    mergeMap(() => {
        const user = getUser(store.value)
        return Api.getRedeems(user.id).pipe(
            switchMap((redeems: RedeemState[]) => {
                return of(stopSubmit('Wallet'))
                    .pipe(startWith(fetchRedeemFulfilled(redeems)))
            }),
            catchError((error: FormErrors<FormData, any>) => {
                return of(stopSubmit('Wallet', error))
            })).pipe(
                startWith(startSubmit('Wallet'))
            )
    })
);