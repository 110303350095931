import React, { } from 'react';
import { NavigationScreenProp } from 'react-navigation';
import { createBrowserApp } from "@react-navigation/web";
import AppNavigator from './navigators/RootNavigator';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './configureStore';
import { Root } from 'native-base';
import Parse from 'parse';
import { AsyncStorage, View, Text } from 'react-native';
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import { sync } from './actions';
import { getUser } from './reducers';
import { RedeemEntity, RewardEntity, WalletEntity } from './utils/Parse';
import { Theme } from './styles';
const bugsnagClient = bugsnag('041757e9ca130f45fb059d907fa63e1c')
bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = bugsnagClient.getPlugin('react')

Parse.setAsyncStorage(AsyncStorage);
Parse.initialize("4Mhcd4qWxrZdAFhHkOXuY70exWWBhK8Y2o18zaEf");
Parse.serverURL = 'https://gloyalty.herokuapp.com/parse'
Parse.masterKey = '9Ybb79eUlftofUeDH7ipPyYcF3sSHB7DrdIjcMbQ'
Parse.Object.registerSubclass('Redeem', RedeemEntity);
Parse.Object.registerSubclass('Reward', RewardEntity);
Parse.Object.registerSubclass('Wallet', WalletEntity);

export interface AppProps {
  navigation: NavigationScreenProp<any, any>
}

const store = configureStore()


const Navigation = createBrowserApp(AppNavigator);

export default class App extends React.Component<AppProps> {

  onBeforeLift() {
    const { dispatch } = store.store
    const user = getUser(store.store.getState())
    if (user != null) {
      dispatch(sync())
    }
  }

  render() {
    const isMobile = navigator.userAgent.indexOf('Mobile') != -1 ||
      navigator.userAgent.indexOf('Android') != -1 ||
      navigator.userAgent.indexOf('iPhone') != -1 ||
      navigator.userAgent.indexOf('Windows Phone') != -1

    return (
      <ErrorBoundary>
        <Root>
          <Provider store={store.store}>
            <PersistGate
              persistor={store.persistor}>
              {isMobile ?
                (<Navigation ref={() => this.onBeforeLift()} />)
                :
                (<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: Theme.color.black }}>
                  <Text style={{ color: Theme.color.white, fontSize: 30, alignSelf: 'center', marginLeft: 15, marginRight: 15 }}>{
                    'This application must be viewed on smartphone! Please switch to a smartphone device.'}
                  </Text>
                </View>)
              }
            </PersistGate>
          </Provider>
        </Root>
      </ErrorBoundary>
    )
  }
}