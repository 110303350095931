import React, { Dispatch } from 'react';
import { StyleSheet, FlatList, View, Text } from 'react-native';
import { Theme } from '../styles';
import { State, Redeem, User } from '../types';
import { getRedeems, getUser } from '../reducers';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { getFetchRedeem } from '../actions';
import { NavigationScreenProp } from 'react-navigation';
import NavigationConstants from '../navigators/NavigationConstants';
import ListEmpty from '../components/ListEmpty';
import ReddemItem from '../components/ReddemItem';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Theme.color.white,
    },
    products: {
        flexGrow: 1,
        backgroundColor: Theme.color.white
    },
    title: {
        fontFamily: 'roboto',
        fontSize: 14,
        fontWeight: 'bold',
        marginLeft: 5,
        marginTop: 10,
        marginRight: 5,
        alignSelf: 'center',
        color: Theme.color.black54
    },
});

export interface WalletProps {
    wallet: Wallet
    reddems: Redeem[],
    getFetchRedeem: any,
    navigation: NavigationScreenProp<any, any>,
    user: User
};

export interface WalletForm {

};

class Wallet extends React.Component<InjectedFormProps<WalletForm, WalletProps> & WalletProps>{

    static navigationOptions = {
        title: 'Wallet',
        headerTintColor: Theme.color.white,
        headerStyle: {
            backgroundColor: Theme.color.black,
        },
        titleStyle: {
            textAlign: 'left'
        },
        headerTitleStyle: {
            fontSize: 24,
            fontWeight: '600',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'flex-start'
        },
    };

    render() {
        const { navigate } = this.props.navigation;
        const { reddems, user } = this.props
        if (!user) {
            const { navigate } = this.props.navigation;
            navigate(NavigationConstants.LOGIN)
            return null
        }
        return (
            <View style={styles.container}>
                <FlatList contentContainerStyle={styles.products}
                    data={reddems}
                    ListEmptyComponent={
                        <ListEmpty text={'Your VAPOX vouchers are empty'}
                            icon={'account-balance-wallet'} />
                    }
                    keyExtractor={(_item, index) => `${index}`}
                    renderItem={({ item }) => <ReddemItem item={item}
                        onPress={() => item.awarded ? null : navigate(NavigationConstants.INVITEPOPUP, { reward: item })} />} />
            </View>
        );
    }
}

const mapStateToProps = (state: State) => {
    return {
        reddems: getRedeems(state).filter(reddem => !reddem.reward.archived)
            .filter(reddem => reddem.reward.active).sort((a, b) => new Date(b.cost).getTime() - new Date(a.createdAt).getTime())
            .sort((a, b) => a.awarded === b.awarded ? 0 : a.awarded ? 1 : -1),
            user: getUser(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<WalletForm>) => {
    return {
        getFetchRedeem: () => dispatch(getFetchRedeem())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm<WalletForm, WalletProps>({
    form: 'Wallet'
})(Wallet))