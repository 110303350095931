import { TypeKeys } from "../constants/TypeKeys";
import { Action } from "redux";
import { PointHistory } from "../types";

export interface HistoryFetchAction extends Action {
    type: TypeKeys.FETCH_HISTORY;
    id: string;
}

export interface HistoryFetchFulfilledAction extends Action {
    type: TypeKeys.FETCH_HISTORY_FULFILLED,
    history: PointHistory[]
}

export const getFetchHistory = (id: string): HistoryFetchAction => ({
    type: TypeKeys.FETCH_HISTORY,
    id
});
export const historyFetchFulfilled = (history: PointHistory[]): HistoryFetchFulfilledAction => ({
    type: TypeKeys.FETCH_HISTORY_FULFILLED,
    history
});