import Parse from 'parse';
import { loginFulfilled, LoginAction, saveUserFulfilled, SaveUserAction, syncFulfilled, SyncAction, registerFulfilled, resetPasswordFulfilled, logout, syncFailed, SendEmailAction, sendEmailFulfilled, LoginStartAction, ResendEmailAction, logoutFulfilled } from '../actions';
import { TypeKeys } from '../constants/TypeKeys';
import { stopSubmit, FormErrors, startSubmit } from 'redux-form';
import { mergeMap, switchMap, startWith, catchError, tap } from 'rxjs/operators'
import { of, zip, throwError, from } from 'rxjs'
import { Epic, ofType } from "redux-observable";
import { User, Retail } from '../types';
import { getUser } from '../reducers';
import Api from './Api';

export const login: Epic = (action$) => action$.pipe(
    ofType(TypeKeys.LOGIN),
    mergeMap((action: LoginAction) => Api.signInWithEmailLink(action.email, action.url).pipe(
        catchError((error: FormErrors<FormData, any>) => {
           return of(stopSubmit('SignUp', error))
        })).pipe(
            startWith(startSubmit('SignUp'))
        ))
);

export const loginStart: Epic = (action$) => action$.pipe(
    ofType(TypeKeys.LOGIN_START),
    mergeMap((action: LoginStartAction) => from(Api.logInWith(action.email, action.url)).pipe(
        switchMap((user: Parse.User) => {
            return zip(Api.getRetailByUser(user), Api.getWalletByUser(user),
                Api.getRedeems(user.id), Api.purchaseHistory(user.id), of(user))
        }),
        switchMap((retailWallet: any) => {
            const retail = retailWallet[0]
            const wallet = retailWallet[1]
            const redeems = retailWallet[2]
            const history = retailWallet[3]
            const user = retailWallet[4]
            return zip(of(retail), of(wallet),
                of(redeems), of(history), of(user), Api.getRewards(retail))
        }),
        switchMap((all: any) => {
            const retail = all[0]
            const wallet = all[1]
            const redeems = all[2]
            const history = all[3]
            const user = all[4]
            const rewards = all[5]
            return of(stopSubmit('Authrization')).pipe(
                startWith(loginFulfilled({
                    id: user.id,
                    email: user.get("email"),
                    phone: user.get("phone"),
                    name: user.get("name")
                }, rewards,
                    retail,
                    wallet,
                    redeems,
                    history))
            )
        }),
        catchError((error: FormErrors<FormData, any>) => {
            return of(stopSubmit('Authrization', error))
        })).pipe(
            startWith(startSubmit('Authrization'))
        ))
);

export const sendEmail: Epic = (action$, store) => action$.pipe(
    ofType(TypeKeys.SEND_EMAIL),
    mergeMap((action: SendEmailAction) => Api.sendSignInLinkToEmail(action.email).pipe(
        switchMap((result: any) => {
            return of(stopSubmit('Login')).pipe(
                startWith(sendEmailFulfilled(action.email))
            )
        }),
        catchError((error: FormErrors<FormData, any>) => {
            return of(stopSubmit('Login', error))
        })).pipe(
            startWith(startSubmit('Login'))
        ))
);

export const resendEmail: Epic = (action$, store) => action$.pipe(
    ofType(TypeKeys.RESEND_EMAIL),
    mergeMap((action: ResendEmailAction) => Api.sendSignInLinkToEmail(action.email).pipe(
        switchMap((result: any) => {
            return of(stopSubmit('SignUp')).pipe(
                startWith(sendEmailFulfilled(action.email))
            )
        }),
        catchError((error: FormErrors<FormData, any>) => {
            return of(stopSubmit('SignUp', error))
        })).pipe(
            startWith(startSubmit('SignUp'))
        ))
);


export const sync: Epic = (action$, store) => action$.pipe(
    ofType(TypeKeys.SYNC),
    mergeMap((action: SyncAction) => {
        const userStore = getUser(store.value)
        return Api.getUser(userStore.id).pipe(
            switchMap((user: Parse.User) => {
                return zip(Api.getRetailByUser(user), Api.getWalletByUser(user),
                    Api.getRedeems(user.id), Api.purchaseHistory(user.id), of(user))
            }),
            switchMap((retailWallet: any) => {
                const retail = retailWallet[0]
                const wallet = retailWallet[1]
                const redeems = retailWallet[2]
                const history = retailWallet[3]
                const user = retailWallet[4]
                return zip(of(retail), of(wallet),
                    of(redeems), of(history), of(user), Api.getRewards(retail))
            }),
            switchMap((all: any) => {
                const retail = all[0]
                const wallet = all[1]
                const redeems = all[2]
                const history = all[3]
                const user = all[4]
                const rewards = all[5]
                return of(syncFulfilled({
                    id: user.id,
                    email: user.get("email"),
                    phone: user.get("phone"),
                    name: user.get("name")
                }, rewards,
                    retail,
                    wallet,
                    redeems,
                    history))

            }),
            catchError(error => {
                if (error.status == 401) {
                    return of(logout())
                }
                return of(syncFailed())
            })
        )
    }));



export const register: Epic = (action$, store) => action$.pipe(
    ofType(TypeKeys.REGISTER),
    mergeMap(action => {
        return Api.getRetail().pipe(
            switchMap((retail: Retail) => {
                return Api.register(action.password,
                    action.email, action.phone, action.name, retail)
            }),
            switchMap((user: Parse.User) => {
                return zip(Api.getRetailByUser(user), Api.getWalletByUser(user),
                    Api.getRedeems(user.id), Api.purchaseHistory(user.id), of(user))
            }),
            switchMap((retailWallet: any) => {
                const retail = retailWallet[0]
                const wallet = retailWallet[1]
                const redeems = retailWallet[2]
                const history = retailWallet[3]
                const user = retailWallet[4]
                return zip(of(retail), of(wallet),
                    of(redeems), of(history), of(user), Api.getRewards(retail))
            }),
            switchMap((all: any) => {
                const retail = all[0]
                const wallet = all[1]
                const redeems = all[2]
                const history = all[3]
                const user = all[4]
                const rewards = all[5]
                return of(stopSubmit('SignUp')).pipe(
                    startWith(registerFulfilled({
                        id: user.id,
                        email: user.get("email"),
                        phone: user.get("phone"),
                        name: user.get("name")
                    }, rewards,
                        retail,
                        wallet,
                        redeems,
                        history))
                )
            }),
            catchError((error: FormErrors<FormData, any>) => {
                return of(stopSubmit('SignUp', error))
            })
        ).pipe(
            startWith(startSubmit('SignUp'))
        )
    }));

export const resetPassword: Epic = (action$, store) => action$.pipe(
    ofType(TypeKeys.RESET_PASSWORD),
    mergeMap(action => Api.resetPassword(action.email).pipe(
        switchMap(() => of(stopSubmit('ResetPassword'))
            .pipe(startWith(resetPasswordFulfilled()))
        ),
        catchError((error: FormErrors<FormData, any>) => {
            return of(stopSubmit('ResetPassword', error))
        })
    ).pipe(
        startWith(startSubmit('ResetPassword'))
    ))
);

export const logOut: Epic = (action$) => action$.pipe(
    ofType(TypeKeys.LOG_OUT),
    mergeMap(() => Api.logout().pipe(
        switchMap(() => of(logoutFulfilled(), stopSubmit('Settings'))),
        catchError((error: FormErrors<FormData, any>) => {
            return of(stopSubmit('Settings', error))
        })
    ).pipe(
        startWith(startSubmit('Settings'))
    )));

export const saveUser: Epic = (action$, store) => action$.pipe(
    ofType(TypeKeys.SAVE_USER),
    mergeMap((action: SaveUserAction) => {
        return Api.saveUser(action.name).pipe(
            switchMap((user: User) => of(stopSubmit('Account'))
                .pipe(
                    startWith(saveUserFulfilled(user))
                )),
            catchError(error => {
                if (error.status == 401) {
                    return of(logout())
                }
                return throwError(action)
            }),
            catchError((error: FormErrors<FormData, any>) => {
                return of(stopSubmit('Account', error))
            })).pipe(
                startWith(startSubmit('Account'))
            )
    })
);