import React from 'react';
import {
    StyleSheet,
    View,
    Text
} from 'react-native'
import { Theme } from '../styles';
import { PointHistory } from '../types';
import moment from 'moment';
import Icon from 'react-native-vector-icons/FontAwesome';

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    dateContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 15,
        marginBottom: 15
    },
    date: {
        fontSize: 15,
        marginLeft: 15,
        color: Theme.color.black87,
        alignSelf: 'center',
    },
    point: {
        fontSize: 20,
        color: Theme.color.black87,
        alignSelf: 'center',
        fontWeight: 'bold'
    },
    star: {
        alignSelf: 'center',
        marginLeft: 3,
        marginRight: 15,
    },
    divider: {
        height: 0.5,
        marginLeft: 15,
        marginRight: 15,
        backgroundColor: Theme.color.black12,
    },
    pointsContainer: {
        flexDirection: 'row'
    }
})

interface Props {
    item: PointHistory
}

class PointHistoryItem extends React.PureComponent<Props> {

    render() {
        const { item } = this.props
        const date = moment(item.date).format('DD/MM/YYYY')
        return (
            <View style={styles.container}>
                <View style={styles.divider} />
                <View style={styles.dateContainer}>
                    <Text style={styles.date}>{date}</Text>
                    <View style={styles.pointsContainer}>
                        <Text style={styles.point}>{item.points.toFixed(0)}</Text>
                        <Icon name='star' size={30} style={styles.star} color={Theme.color.black} />
                    </View>
                </View>
                <View style={styles.divider} />
            </View>
        )
    }
}

export default PointHistoryItem