import React, { Dispatch } from 'react';
import { StyleSheet, FlatList, View, Text, TouchableOpacity, StatusBar, ScrollView } from 'react-native';
import { Theme } from '../styles';
import RewardItem from '../components/RewardItem';
import Icon from 'react-native-vector-icons/FontAwesome';
import * as Animatable from 'react-native-animatable';
import { Reward, User, Shop, State, Wallet } from '../types';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { getWallet, getRewards, getUser } from '../reducers';
import {
    NavigationScreenProp,
    NavigationScreenProps,
    NavigationStackScreenOptions,
    NavigationEvents
} from 'react-navigation';
import NavigationConstants from '../navigators/NavigationConstants';
import { pushNotification, sync } from '../actions';

export interface HomeProps {
    rewards: Reward[],
    user: User,
    shop: Shop,
    wallet: Wallet,
    pushNotification: any
    navigation: NavigationScreenProp<any, any>
};

export interface HomeForm {

};

const styles = StyleSheet.create({
    safeArea: {
        backgroundColor: Theme.color.black
    },
    container: {
        flexGrow: 1,
        backgroundColor: Theme.color.black,
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
        marginLeft: 5,
        marginTop: 10,
        marginRight: 5,
        alignSelf: 'center',
        color: Theme.color.black54
    },
    products: {
        flexGrow: 1,
        backgroundColor: Theme.color.white
    },
    menuRight: {
        marginRight: 10,
        height: 30,
        width: 30,
        alignSelf: 'center'
    },
    countPoints: {
        color: Theme.color.white,
        fontSize: 72,
        marginTop: 10,
        fontWeight: 'bold'
    },
    vapoxPoints: {
        color: Theme.color.white,
        fontSize: 14,
        marginTop: 5,
        letterSpacing: 0.05,
        paddingBottom: 40,
        fontWeight: 'bold'
    },
    pointsContainer: {
        flex: 1,
        paddingTop: 28,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Theme.color.black
    },
    welcomeContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Theme.color.white
    },
    welcomeText: {
        marginTop: 35,
        fontSize: 20,
        color: Theme.color.black54,
        textAlign: 'center'
    }
});

interface NavStateParams {
    onPointsHistory: any,
    onRedeemInformation: any,
}

type NavigationOptionsFn<TParams = any> = (props: NavigationScreenProps<TParams>) => NavigationStackScreenOptions

class Home extends React.Component<InjectedFormProps<HomeForm, HomeProps> & HomeProps> {

    static navigationOptions: NavigationOptionsFn<NavStateParams> = ({ navigation, screenProps }) => ({
        headerTintColor: Theme.color.white,
        headerStyle: {
            borderBottomColor: 'transparent',
            borderBottomWidth: 0,
            backgroundColor: Theme.color.black,
        },
        titleStyle: {
            textAlign: 'left',

        },
        headerTitleStyle: {
            fontSize: 24,
            fontWeight: '600',

            flex: 1,
            justifyContent: 'center',
        },
        headerRight: (
            <TouchableOpacity onPress={() => navigation.navigate(NavigationConstants.POINTHISTORY)}>
                <Icon name='list-alt' size={24} color='#fff' style={styles.menuRight} />
            </TouchableOpacity>
        )
    });

    componentDidMount() {
        const { navigate } = this.props.navigation;
        // @ts-ignore
        const { user, sync } = this.props
        if (user) {
            navigate(NavigationConstants.HOME)
            sync()
            // @ts-ignore
            this.poller = setInterval(() => sync(), 5000)
        } else {
            navigate(NavigationConstants.APP)
        }
    };

    // @ts-ignore
    render() {
        const { rewards, wallet, user } = this.props
        const { navigate } = this.props.navigation;

        if (!user) {
            const { navigate } = this.props.navigation;
            navigate(NavigationConstants.LOGIN)
            return null
        }
        //@ts-ignore
        this.props.sync()
      
        return (
            <ScrollView style={styles.container}>
                <NavigationEvents
                    //@ts-ignore
                    onWillFocus={() =>   alert("ddddd")}
                />
                <StatusBar barStyle="light-content" />
                <FlatList contentContainerStyle={styles.products}
                    data={rewards}
                    bounces={false}
                    ListHeaderComponent={<View><View style={styles.pointsContainer}>
                        <Animatable.View
                            animation="pulse"
                            iterationCount="infinite">
                            <Icon name="star"
                                size={40}
                                style={{ marginBottom: 10 }}
                                color={Theme.color.white} />
                        </Animatable.View>
                        <Text style={styles.countPoints}>{wallet.points.toFixed(0)}</Text>
                        <Text style={styles.vapoxPoints}>{'VAPOX POINTS'}</Text>
                    </View>

                    </View>
                    }
                    keyExtractor={(_item, index) => `${index}`}
                    renderItem={({ item }) =>

                        <RewardItem item={item}
                            onPress={() => navigate(NavigationConstants.REDEEMINFROMATION, { reward: item })}
                            remainig={wallet.points - item.cost}
                            showRedeem={true}
                        />} />
            </ScrollView>
        );
    }
}

const mapStateToProps = (state: State) => {
    const wallet = getWallet(state);
    return {
        user: getUser(state),
        wallet: wallet,
        rewards: getRewards(state).filter(reward => !reward.archived)
            .filter(reward => reward.active).sort((a, b) => a.cost - b.cost),
    }
}

const mapDispatchToProps = (dispatch: Dispatch<HomeForm>) => {
    return {
        pushNotification: () => dispatch(pushNotification()),
        sync: () => dispatch(sync()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm<HomeForm, HomeProps>({
    form: 'Home'
})(Home))