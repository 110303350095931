import React from 'react';
import {
    View,
    StyleSheet,
    Text,
} from 'react-native'
import { Theme } from '../styles';
import { Button } from 'native-base';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { Reward } from '../types';
const styles = StyleSheet.create({
    star: {
        alignSelf: 'center',
        marginLeft: 10,
        marginRight: 10
    },
    gift: {
        alignSelf: 'center',
        marginLeft: 10,
        marginRight: 10,
    },
    showRedeemContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    bottomContainer: {
        flex: 1,
        marginTop: 30,
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    starContainer: {
        flexDirection: 'row',
        flex: 1
    },
    pointsAwayContainer: {
        flex: 1,
        marginBottom: 20,
        marginLeft: 20,
        marginRight: 20
    },
    redeem: {
        fontFamily: 'roboto',
        marginLeft: 10,
        color: Theme.color.white,
        fontSize: 20,
        fontWeight: 'bold'
    },
    redeemContainer: {
        height: 40,
        backgroundColor: Theme.color.black,
    },
    redeemContainerDisabled: {
        height: 40,
        backgroundColor: Theme.color.black54,
    },
    pointsNeeded: {
        color: Theme.color.black54,
        fontSize: 12,
        marginTop: 5,
        textAlign: 'left',
        alignSelf: 'flex-end'
    },
    starsCount: {
        fontFamily: 'roboto',
        fontSize: 30,
        alignSelf: 'center',
        color: Theme.color.black,
        fontWeight: 'bold'
    },
})

interface Props {
    item: Reward,
    remainig: number,
    onPress: Function,
    showRedeem: boolean
}

class PointsRedeem extends React.PureComponent<Props> {

    render() {
        const { item, remainig, onPress, showRedeem } = this.props
        return (
            <View style={styles.pointsAwayContainer}>
                <View style={styles.bottomContainer}>
                    <View style={styles.starContainer}>
                        <Text style={styles.starsCount} numberOfLines={1}>{item.cost.toFixed(0)}</Text>
                        <Icon name='star' size={30} style={styles.star} color={Theme.color.black} />
                    </View>
                    {showRedeem && <View style={styles.showRedeemContainer} >
                        <View>
                            <Button iconRight
                                disabled={remainig < 0}
                                light style={remainig < 0 ? styles.redeemContainerDisabled : styles.redeemContainer}
                                onPress={() => onPress()}>
                                <Text style={styles.redeem}>{"REDEEM"}</Text>
                                <Icon name='redeem' size={24} style={styles.gift} color={Theme.color.white} />
                            </Button>
                        </View>
                    </View>}
                </View>
                {remainig < 0 && <Text style={styles.pointsNeeded}>{`${Math.abs(remainig)} POINTS AWAY`} </Text>}
            </View>
        )
    }
}

export default PointsRedeem