import {
    StyleSheet,
} from 'react-native';
import Theme from './Theme';

export default StyleSheet.create({
    error: {
        fontSize: 14,
        alignSelf: 'center',
        color: 'red',
        marginTop: 10,
    },
    title: {
        fontSize: 25,
        marginTop: 20,
        alignSelf: 'center',
        color: Theme.color.white
    },
})