import { Observable, of } from "rxjs";
import { ajax } from 'rxjs/ajax';
import { from, bindNodeCallback, zip } from 'rxjs'
import { Wallet, User, Reward, Retail, PointHistory, RedeemState } from "../types";
import { map, mergeMap, switchMap } from "rxjs/operators";
import Parse from 'parse';
import { RedeemEntity, RewardEntity, WalletEntity, RetailEntity } from '../utils/Parse'
import Auth0 from 'auth0-js-react'
import { Auth0Keys } from '../constants';

const auth0 = new Auth0({
    domain: Auth0Keys.Domain,
    clientID: Auth0Keys.ClientId,
    callbackOnLocationHash: true,
    callbackURL: `${Auth0Keys.CallbackDomain}/app/auth/signup`
})

export default class Api {

    static sendSignInLinkToEmail(email: string): Observable<{}> {
        const requestEmailCodeAsObservable = bindNodeCallback(
            callback => auth0.requestEmailCode({
                email: email,
                callbackURL: `${Auth0Keys.CallbackDomain}/app/auth/signup?email=${email}`
            }, callback));

        return requestEmailCodeAsObservable()
    }

    static signInWithEmailLink(email: string, code: string): Observable<any> {
        const verifyEmailCodeAsObservable = bindNodeCallback(
            callback => auth0.verifyEmailCode({
                email: email,
                code: code,
                callbackURL: `${Auth0Keys.CallbackDomain}/app/auth/signup?email=${email}`
            }, callback));

        return verifyEmailCodeAsObservable().pipe(
            switchMap((user: any) => {
                return of(user)
            }),
        )
    }

    static logInWith(email: string, accessToken: string): Observable<Parse.User> {
        // @ts-ignore
        return Api.request(`https://${Auth0Keys.Domain}/userinfo`, 'GET', accessToken).pipe(
            switchMap((profile) => {
                //@ts-ignore
                const authData = { authData: { id: profile.response.sub, access_token: accessToken } }
                return from(Api.getRetail()).pipe(
                    switchMap((retail) => {
                        const userQuery = new Parse.Query(Parse.User)
                        userQuery.equalTo("retail", RetailEntity.createWithoutData(retail.id))
                        userQuery.include("email")
                        userQuery.equalTo("email", email)
                        return from(userQuery.first()).pipe(
                            switchMap((user?: Parse.User) => {
                                return zip(of(retail), of(user))
                            }))
                    }),
                    switchMap((userRetail: any) => {
                        const user = userRetail[1]
                        const retail = userRetail[0]
                        const newUser = new Parse.User()
                        if (user === undefined || user === null) {
                            const Wallet = Parse.Object.extend("Wallet");
                            const wallet = new Wallet();
                            wallet.set("points", 0);
                            newUser.set("email", email.toLowerCase());
                            newUser.set("username", email.toLowerCase())
                            newUser.set("wallet", wallet)
                            newUser.set("retail", RetailEntity.createWithoutData(retail.id))
                            // @ts-ignore
                            return from(newUser._linkWith('auth0', authData)).pipe(
                                map(user => user)
                            )
                        } else {
                            // @ts-ignore
                            return from(newUser._linkWith('auth0', authData)).pipe(
                                map(user => user)
                            )
                        }
                    })
                )
            })
        )
    }

    static getRedeems(userId: string): Observable<RedeemState[]> {
        const redeemsQuery = new Parse.Query(RedeemEntity);
        redeemsQuery.equalTo("user", Parse.User.createWithoutData(userId))
        redeemsQuery.include("reward")
        return from(redeemsQuery.find()).pipe(
            map(redeems => redeems.map(redeem => ({
                id: redeem.id,
                cost: redeem.points,
                rewardId: redeem.get("reward").id,
                awarded: redeem.awarded,
                createdAt: redeem.createdAt
            })))
        )
    }

    static getRewards(retail: Retail): Observable<Reward[]> {
        const rewardsQuery = new Parse.Query('Reward');
        rewardsQuery.equalTo("retail", RetailEntity.createWithoutData(retail.id))
        return from(rewardsQuery.find()).pipe(
            map(rewards => rewards.map(reward => ({
                id: reward.id,
                cost: reward.get("cost"),
                name: reward.get("name"),
                description: reward.get("description"),
                image: reward.get("photo") && reward.get("photo").url(),
                active: reward.get("active"),
                archived: reward.get("archived"),
                createdAt: reward.createdAt
            })))
        )
    }

    static purchaseHistory(userId: string): Observable<PointHistory[]> {
        const purchaseQuery = new Parse.Query('Purchase');
        purchaseQuery.equalTo("user", Parse.User.createWithoutData(userId))
        return from(purchaseQuery.find()).pipe(
            map(histories => histories.map(history => ({
                date: history.createdAt,
                points: history.get("points")
            })))
        )
    }

    static redeem(userId: string, rewardId: string, cost: number): Observable<string> {
        const redeem = new RedeemEntity();
        redeem.set("reward", RewardEntity.createWithoutData(rewardId));
        redeem.set("user", Parse.User.createWithoutData(userId));
        redeem.points = cost;
        redeem.awarded = false
        return from(redeem.save()).pipe(
            map((reward: RedeemEntity) => reward.id)
        )
    }

    static getUser(userId: string): Observable<Parse.User> {
        const user = new Parse.Query(Parse.User)
        user.equalTo("objectId", userId)
        return from(user.first()).pipe(
            map((user => user!!)
            )
        )
    }

    static purchase(walletId: string, points: number): Observable<number> {
        const wallet = WalletEntity.createWithoutData(walletId)
        return from(wallet.fetch()).pipe(
            mergeMap((wallet: WalletEntity) => {
                wallet.increment("points", -points)
                return from(wallet.save())
            }),
            map(wallet => wallet.points)
        )
    }

    static getWallet(walletId: string): Observable<Wallet> {
        const wallet = WalletEntity.createWithoutData(walletId)
        return from(wallet.fetch()).pipe(
            map(wallet => ({
                id: wallet.id,
                points: wallet.get("points")
            }))
        )
    }

    static saveUser(name: string): Observable<User> {
        const user = Parse.User.current();
        user!!.set("name", name)
        return from(user!!.save()).pipe(
            map(user => ({
                id: user.id,
                email: user.get("email"),
                phone: user.get("phone"),
                name: user.get("name"),
            }))
        )
    }

    static login(email: string, password: string): Observable<Parse.User> {
        return from(Parse.User.logIn(email.toLowerCase(), password)).pipe(
            map(user => user)
        )
    }

    static logout(): Observable<{}> {
        return from(Parse.User.logOut())
    }

    static resetPassword(email: string): Observable<Parse.User> {
        return from(Parse.User.requestPasswordReset(email)).pipe(
            map(user => user)
        )
    }

    static getRetail(): Observable<Retail> {
        const retail = new Parse.Query('Retail');
        retail.equalTo("appId", "com.grappex.loayalty.vapox");
        return from(retail.first()).pipe(
            map(retail => ({
                id: retail!!.id,
                appId: retail!!.get("appId"),
                name: retail!!.get("name")
            }))
        )
    }

    static getRetailByUser(user: Parse.User): Observable<Retail> {
        const retail = user.get("retail") as Parse.Object
        return from(retail.fetch()).pipe(
            map(retail => ({
                id: retail!!.id,
                appId: retail!!.get("appId"),
                name: retail!!.get("name")
            }))
        )
    }

    static getWalletByUser(user: Parse.User): Observable<Wallet> {
        const walllet = user.get("wallet") as Parse.Object
        console.log("fetch wallet" + walllet)
        return from(walllet.fetch()).pipe(
            map(wallet => ({
                id: wallet.id,
                points: wallet.get("points")
            }))
        )
    }

    static register(password: string,
        email: string, phone: string, name: string, retail: Retail): Observable<Parse.User> {
        const user = new Parse.User();
        const Wallet = Parse.Object.extend("Wallet");
        const wallet = new Wallet();
        wallet.set("points", 0);
        user.set("password", password);
        user.set("name", name)
        user.set("email", email.toLowerCase());
        user.set("username", email.toLowerCase())
        user.set("phone", phone);
        user.set("wallet", wallet)
        user.set("retail", RetailEntity.createWithoutData(retail.id))
        return from(user.signUp(null)).pipe(
            map(user => user)
        )
    }

    static request = (url, method, token, body, inHeaders = {}) => {
        let outHeaders = {
            'Content-Type': 'application/json',
            ...inHeaders,
        }
        if (token) {
            outHeaders = {
                ...outHeaders,
                //@ts-ignore
                'Authorization': `Bearer ${token}`
            }
        }
        return ajax({
            url: url,
            method: method,
            headers: outHeaders,
            body: body,
            responseType: 'json',
            async: true
        })
    }

}