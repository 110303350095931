
import { TypeKeys } from "../constants/TypeKeys";
import { ActionTypes } from "../actions";
import { PointHistory } from "../types";

const history = (state: PointHistory[] = null!, action: ActionTypes): PointHistory[] => {
    switch (action.type) {
        case TypeKeys.LOGIN_FULFILLED:
        case TypeKeys.REGISTER_FULFILLED:
        case TypeKeys.SYNC_FULFILLED:
            const { history } = action
            return history
        case TypeKeys.LOG_OUT_FULFILLED:
            return null!
        default:
            return state
    }
}

export const getHistory = (state: PointHistory[]): PointHistory[] => state

export default history
