import { TypeKeys } from "../constants/TypeKeys";
import { ActionTypes } from "../actions";
import { Reward } from "../types";

const reward = (state: Reward[] = [], action: ActionTypes): Reward[] => {
    switch (action.type) {
        case TypeKeys.SYNC_FULFILLED:
        case TypeKeys.REGISTER_FULFILLED:
        case TypeKeys.LOGIN_FULFILLED:
            const { rewards } = action
            return rewards
        case TypeKeys.LOG_OUT_FULFILLED:
            return []
        default:
            return state
    }
}

export const getRewards = (state: Reward[]): Reward[] => state
export const getReward = (state: Reward[], rewardId:string): Reward | undefined => state.find(reward => reward.id == rewardId)

export default reward