const NavigationConstants = {
    HOME: 'root',
    APP: 'app',
    LOGIN: 'login',
    AUTHRIZATION: 'authrization',
    START: 'Start',
    SIGNUP: 'signup',
    RESETPASSWORD: 'ResetPassword',
    POINTHISTORY: 'history',
    REDEEMINFROMATION: 'redeem',
    Wallet: 'wallet',
    Account: 'account',
    POINTSPOPUP: 'PointsPopup',
    INVITEPOPUP: 'invite'
}

export default NavigationConstants