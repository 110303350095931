import React from 'react';
import { connect } from 'react-redux'
import {
    StyleSheet, ScrollView,
    Platform,
    View,
    StatusBar
} from 'react-native';
import {
    reduxForm,
    getFormSubmitErrors,
    InjectedFormProps,
    formValueSelector,
    Field,
} from 'redux-form';
import { Button, Text, Toast } from 'native-base';
import { Theme } from '../styles';
import { login, resendEmail } from '../actions';
import Loader from '../components/Loader';
import { NavigationScreenProp } from 'react-navigation';
import NavigationConstants from '../navigators/NavigationConstants';
import { State } from '../types';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { getUser } from '../reducers';
import { Dispatch } from 'redux';
import EditText from '../components/EditText';
import { notNull } from '../utils/Validations';

export interface SignUpScreenProps {
    resend: any
    submitError: any,
    navigation: NavigationScreenProp<any, any>,
    email: string;
    url: string,
    login: any,
    isResendEmail: boolean
};

export interface SignUpForm {
    email: string;
};


class SignUp extends React.Component<InjectedFormProps<SignUpForm, SignUpScreenProps> & SignUpScreenProps> {
    static navigationOptions = {
        title: '',
        backButtonTitle: ' ',
        headerTintColor: Theme.color.white,
        titleStyle: {
            textAlign: 'left'
        },
        headerStyle: {
            backgroundColor: Theme.color.black
        },
        headerTitleStyle: {
            fontFamily: 'roboto'
        },
    };

    _resend = () => {
        const { resend, email } = this.props
        const emailUrl = this.getUrlParams("email")
        resend(emailUrl ? emailUrl : email)
    }

    _send = (values) => {
        const { login, email } = this.props
        login(email, values.code)
    }


    getUrlParams = (search) => {
        var url_string = window.location.href;
        var url = new URL(url_string);
        var key = url.searchParams.get(search);
        return key
    }

    getAccessToken = (search) => {
        let hashes = search.slice(search.indexOf('#') + 1).split('&')
        return hashes.reduce((params, hash) => {
            let [key, val] = hash.split('=')
            return Object.assign(params, { [key]: decodeURIComponent(val) })
        }, {})
    }

    componentWillMount() {
        //@ts-ignore
        const { user } = this.props
        const { navigate } = this.props.navigation;
        if(user) {
            navigate(NavigationConstants.HOME)
        } 
    }

    componentWillReceiveProps(nextProps: SignUpScreenProps) {
        const { submitting, navigation } = this.props
        const hasErrors = Object.keys(nextProps.submitError).length > 0;
        // @ts-ignore
        const success = submitting && !nextProps.submitting;
        // @ts-ignore
        if (success && nextProps.user) {
            navigation.navigate(NavigationConstants.HOME)
        } else if (success && !hasErrors) {
            Toast.show({
                text: "Check your email please",
                buttonText: "OK",
                type: "success",
            })
        } else if (success && hasErrors) {
            Toast.show({
                //@ts-ignore
                text: "Operation failed! please try again ",
                buttonText: "Try again",
                duration: 30000,
                type: "danger",
            })
        }
    }

    componentDidMount() {
        const { navigation } = this.props
        if (window.location.href) {
            const access_token = this.getAccessToken(window.location.href).access_token
            const email = this.getUrlParams("email")
            if (access_token && email) {
                navigation.navigate(NavigationConstants.AUTHRIZATION, {
                    access_token: access_token,
                    email: email
                })
            }
        }
    }

    render() {
        // @ts-ignore
        const { handleSubmit, submitting, user } = this.props;
        if (user) {
            // const { navigate } = this.props.navigation;
            // navigate(NavigationConstants.HOME)
            // return null
        }
        return (
            <ScrollView contentContainerStyle={[styles.container]} keyboardDismissMode={Platform.OS == 'ios' ? "on-drag" : "none"}>
                <StatusBar barStyle={Platform.OS == 'ios' ? 'dark-content' : 'light-content'} />
                <View style={{ marginBottom: 30 }}>
                    <Icon name={'email'} size={150} color={Theme.color.white} style={styles.icon} />
                    <Text style={styles.title}>{"Great!"}</Text>
                    <Text style={styles.description}>{"Please confirm the code\nthat we've sent to your email."}</Text>
                    <Field
                        name={'code'}
                        component={EditText}
                        title={"Verification code"}
                        placeholderTextColor={Theme.color.white50}
                        validate={[notNull]}
                        textStyle={styles.inputCode}
                        secureTextEntry={false} />

                    <Button full bordered light
                        onPress={handleSubmit(this._send)}
                        style={styles.send}>
                        <Text style={styles.sendText}>Confirm</Text>
                    </Button>
                    {/*
                    //Temporarily remove re-send email button
                    <Button full bordered light
                        onPress={handleSubmit(this._resend)}
                        style={styles.signUp}>
                        <Text style={styles.signUpText}>Re-send Email</Text>
                    </Button> */}
                </View>
                <Loader isVisible={submitting} />
            </ScrollView>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Theme.color.black
    },
    icon: {
        alignSelf: 'center'
    },
    title: {
        color: Theme.color.white,
        alignSelf: 'center',
        marginTop: 30,
        fontSize: 42,
        fontWeight: "bold",
    },
    description: {
        color: Theme.color.white,
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: 12,
        marginBottom: 30,
        fontSize: 17,
    },
    signUp: {
        borderRadius: 4,
        marginTop: 12,
        height: 54,
        backgroundColor: Theme.color.mercury,
        fontFamily: "Roboto"
    },
    send: {
        borderRadius: 4,
        marginTop: 30,
        height: 54,
        backgroundColor: Theme.color.white,
    },
    inputCode: {
        textAlign: 'center'
    },
    sendText: {
        color: Theme.color.black,
        textAlign: 'center',
        fontSize: 22,
        fontWeight: "500",
        fontFamily: 'Roboto'
    },
    signUpText: {
        color: Theme.color.white,
        fontSize: 22,
        fontFamily: "Roboto"
    },
    email: {
        borderRadius: 4,
        marginTop: 30,
        height: 54,
        backgroundColor: Theme.color.white,
    },
    emailText: {
        color: Theme.color.black,
        fontSize: 22,
        fontWeight: 'bold'
    },
});

const selector = formValueSelector('SignUp')
const mapStateToProps = (state: State, ownProps: any) => {
    const url = ownProps.navigation.getParam('url') || null
    const email = ownProps.navigation.getParam('email') || null

    return {
        user: getUser(state),
        submitError: getFormSubmitErrors('SignUp')(state),
        url: url,
        email: email
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        resend: (email: string) => dispatch(resendEmail(email)),
        login: (email: string, url: string) => dispatch(login(email, url)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm<SignUpForm, SignUpScreenProps>({
    form: 'SignUp'
})(SignUp))