import React from 'react';
import { connect } from 'react-redux'
import {
    StyleSheet,
    Platform,
    View,
    StatusBar
} from 'react-native';
import {
    reduxForm,
    getFormSubmitErrors,
    InjectedFormProps,
} from 'redux-form';
import { Button, Text, Toast } from 'native-base';
import { Theme } from '../styles';
import { login, resendEmail, loginStart } from '../actions';
import Loader from '../components/Loader';
import { NavigationScreenProp } from 'react-navigation';
import NavigationConstants from '../navigators/NavigationConstants';
import { State } from '../types';
import { getUser } from '../reducers';
import { Dispatch } from 'redux';

export interface SignUpScreenProps {
    resend: any
    submitError: any,
    navigation: NavigationScreenProp<any, any>,
    email: string;
    access_token: string,
    login: any,
    isResendEmail: boolean
};

export interface SignUpForm {
    email: string;
};


class Authrization extends React.Component<InjectedFormProps<SignUpForm, SignUpScreenProps> & SignUpScreenProps> {
    static navigationOptions = {
        title: '',
        backButtonTitle: ' ',
        headerTintColor: Theme.color.white,
        titleStyle: {
            textAlign: 'left'
        },
        headerStyle: {
            backgroundColor: Theme.color.black
        },
        headerTitleStyle: {
            fontFamily: 'roboto'
        },
    };

    _send = (values) => {
        const { login, email } = this.props
        login(email, values.code)
    }


    getUrlParams = (search) => {
        let hashes = search.slice(search.indexOf('?') + 1).split('&').slice(search.indexOf('#') + 1).split('&')
        return hashes.reduce((params, hash) => {
            let [key, val] = hash.split('=')
            return Object.assign(params, { [key]: decodeURIComponent(val) })
        }, {})
    }

    componentWillReceiveProps(nextProps: SignUpScreenProps) {
        const { submitting, navigation, submitError } = this.props
        const hasErrors = Object.keys(nextProps.submitError).length > 0;
        // @ts-ignore
        const success = submitting && !nextProps.submitting;
        // @ts-ignore
        if (success && nextProps.user) {
            navigation.navigate(NavigationConstants.HOME)
        } else if (success && hasErrors) {
            navigation.goBack()
            navigation.navigate(NavigationConstants.LOGIN)
            Toast.show({
                //@ts-ignore
                text: "Oops! Something went wrong! Please try again ",
                buttonText: "Try again",
                duration: 30000,
                type: "danger",
            })
        }
    }

    componentDidMount() {
        const { handleSubmit, login, access_token, email } = this.props
        if (access_token && email) {
            handleSubmit(login(email, access_token))
        }
    }

    render() {
        const { submitting } = this.props;
        return (
            <View style={styles.container}>
                <StatusBar barStyle={Platform.OS == 'ios' ? 'dark-content' : 'light-content'} />
                <Loader isVisible={submitting} />
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});
const mapStateToProps = (state: State, ownProps: any) => {
    const access_token = ownProps.navigation.getParam('access_token') || null
    const email = ownProps.navigation.getParam('email') || null

    return {
        user: getUser(state),
        submitError: getFormSubmitErrors('Authrization')(state),
        access_token: access_token,
        email: email
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        resend: (email: string) => dispatch(resendEmail(email)),
        login: (email: string, url: string) => dispatch(loginStart(email, url)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm<SignUpForm, SignUpScreenProps>({
    form: 'Authrization'
})(Authrization))