import { combineEpics } from 'redux-observable';
import { login, logOut, saveUser, sync, register, resetPassword, sendEmail, loginStart, resendEmail } from './auth';
import { getHistory } from './history';
import { redeemReward, getRedeem } from './redeem';

export default combineEpics(
    login,
    getHistory,
    logOut,
    saveUser,
    sync,
    register,
    resetPassword,
    redeemReward,
    getRedeem,
    sendEmail,
    loginStart,
    resendEmail
);