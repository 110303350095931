import React from 'react';
import {
    View,
    StyleSheet,
    Text,
    Image,
} from 'react-native'
import { Theme } from '../styles';
import { Card } from 'native-base';
import { Reward } from '../types';
import images from '../assets';
import PointsRedeem from './PointsRedeem';

interface Props {
    item: Reward,
    remainig: number,
    onPress: Function,
    showRedeem: boolean
}

class RewardItem extends React.PureComponent<Props> {

    render() {
        const { item, remainig, onPress, showRedeem } = this.props
        return (
            <View style={item.active && styles.container}>
                <Card style={{ elevation: 5 }}>
                    <View style={styles.productContainer}>
                        <View style={styles.headerContainer} >
                       
                                <Image style={styles.icon}
                                    resizeMode={'contain'}
                                    // @ts-ignore
                                    source={item.image ? { uri: item.image } : images.placeholder} />
                                
                            
                        </View>
                        <View style={styles.titleContainer}>
                            <Text style={styles.title}>{item.name}</Text>
                            <Text style={styles.description}>{item.description}</Text>
                        </View>
                    </View>
                    <PointsRedeem
                        item={item}
                        remainig={remainig}
                        showRedeem={showRedeem}
                        onPress={onPress} />
                </Card>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        marginLeft: 10,
        marginRight: 10,
        marginTop: 3,
        marginBottom: 3
    },
    headerContainer: {
        flex: 1,
    },
    showRedeemContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    productContainer: {
        flex: 1,
        marginTop: 20,
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    title: {
        fontSize: 22,
        color: Theme.color.black,
        fontWeight: 'bold',
        alignSelf: 'flex-end',
        textAlign: 'right',
        flexWrap: 'wrap',
    },
    description: {
        fontSize: 16,
        marginTop: 5,
        alignSelf: 'flex-end',
        textAlign: 'right',
        color: Theme.color.black,
    },
    titleContainer: {
        flex: 1.8,
        marginRight: 20,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flexWrap: 'wrap',
    },
    icon: {
        width: 80,
        height: 80,
        marginLeft: 20,
        backgroundColor: Theme.color.white
    }
})

export default RewardItem