import React from 'react';
import {
    View,
    StyleSheet,
    Text,
    TouchableWithoutFeedback,
} from 'react-native'
import { Theme } from '../styles';
import { Card } from 'native-base';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { Redeem } from '../types';
import moment from 'moment-timezone';

interface Props {
    item: Redeem,
    onPress: Function
}

class ReddemItem extends React.PureComponent<Props> {

    render() {
        const { item, onPress } = this.props
        const date = moment(item.createdAt).format('DD/MM/YYYY')
        return (
            <TouchableWithoutFeedback onPress={() => onPress()}>
                <View style={[styles.container]}>
                    <Card style={[{ borderRadius: 5, elevation: 5 }, item.awarded ? styles.cardUsed : styles.cardActive]}>
                        <View style={styles.productContainer}>
                            <View style={[, item.awarded ? styles.circleUsed : styles.circleActive]}>
                                <Icon name={item.awarded ? 'check' : 'redeem'}
                                    size={45} color={item.awarded ? Theme.color.white
                                        : Theme.color.black} />
                            </View>
                            <View style={styles.titleContainer}>
                                <Text style={item.awarded ? styles.titleUsed : styles.titleActive}>{item.reward.name}</Text>
                                {item.awarded ?

                                    <Text style={styles.date}>{`Used on ${date}`}</Text>
                                    :
                                    <Text style={styles.activeText}>{'Bring this to VAPOX to collect your reward'}</Text>}
                            </View>
                        </View>
                    </Card>
                </View>
            </TouchableWithoutFeedback>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        marginLeft: 10,
        marginRight: 10,
        marginTop: 3,
        marginBottom: 3
    },
    productContainer: {
        flex: 1,
        marginTop: 20,
        marginLeft: 20,
        marginBottom: 20,
        justifyContent: 'center',
        flexDirection: 'row'
    },
    imageContainer: {
        flex: 1,
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    starContainer: {
        flexDirection: 'row',
    },
    date: {
        fontSize: 17,
        marginTop: 20,
        color: Theme.color.nobel,
        fontWeight: 'bold'
    },
    titleActive: {
        fontSize: 25,
        color: Theme.color.white,
        fontWeight: 'bold',
        flexWrap: 'wrap'
    },
    titleUsed: {
        fontSize: 25,
        color: Theme.color.black,
        fontWeight: 'bold',
        flexWrap: 'wrap'
    },
    active: {
        fontSize: 12,
        alignSelf: 'center',
        color: Theme.color.white,
    },
    titleContainer: {
        flex: 2,
        marginLeft: 20,
        alignSelf: 'center',
    },
    circleActive: {
        width: 80,
        height: 80,
        borderRadius: 80 / 2,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Theme.color.white
    },
    circleUsed: {
        width: 80,
        height: 80,
        borderRadius: 80 / 2,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Theme.color.black
    },
    cardActive: {
        backgroundColor: Theme.color.black
    },
    cardUsed: {
        backgroundColor: Theme.color.white
    },
    activeText: {
        fontSize: 17,
        marginTop: 10,
        paddingRight:20,
        color: Theme.color.white60,
        fontWeight: 'bold'
    }
})

export default ReddemItem