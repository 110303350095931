import { TypeKeys } from "../constants/TypeKeys";
import { ActionTypes } from "../actions";
import { Wallet } from "../types";

const wallet = (state: Wallet = null!, action: ActionTypes): Wallet => {
  switch (action.type) {
    case TypeKeys.REGISTER_FULFILLED:
    case TypeKeys.SYNC_FULFILLED:
    case TypeKeys.LOGIN_FULFILLED:
      const { wallet } = action
      return {
        id: wallet.id,
        points: wallet.points != null ? wallet.points : 0
      }
    case TypeKeys.REWARD_REDEEM_FULFILLED:
    case TypeKeys.ADD_WALLET:
      return {
        ...state,
        points: Number(action.balance)
      }
    case TypeKeys.LOG_OUT_FULFILLED:
      return null!
    default:
      return state
  }
}

export const getWallet = (state: Wallet): Wallet => state

export default wallet