import React from 'react';
import {
    View,
    StyleSheet,
    Text,
} from 'react-native'
import { Theme } from '../styles';
import { Card } from 'native-base';
import Icon from 'react-native-vector-icons/MaterialIcons';

interface Props {
    icon: string,
    description: string
}

class SettingsCard extends React.PureComponent<Props> {

    render() {
        const { icon, description } = this.props
        return (
            <View style={[styles.container]}>
                <Card style={[{ borderRadius: 4, elevation: 10 }, styles.cardUsed]}>
                    <View style={styles.productContainer}>
                        <Icon name={icon}
                        style={{alignSelf:'center'}}
                            size={45} color={Theme.color.black} />
                        <View style={styles.titleContainer}>
                            <Text style={styles.titleUsed}>{description}</Text>
                        </View>
                    </View>
                </Card>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        marginLeft: 10,
        marginRight: 10,
        marginTop: 20,
        marginBottom: 3
    },
    productContainer: {
        flex: 1,
        marginTop: 20,
        marginLeft: 20,
        marginBottom: 20,
        justifyContent: 'center',
        flexDirection: 'row'
    },
    titleUsed: {
        fontSize: 12,
        color: Theme.color.black60,
        flexWrap: 'wrap'
    },
    titleContainer: {
        flex: 2,
        marginLeft: 15,
        marginRight: 15,
        alignSelf: 'center',
    },
    cardUsed: {
        backgroundColor: Theme.color.white
    },
})

export default SettingsCard