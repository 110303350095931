import { TypeKeys } from "../constants/TypeKeys";
import { Action } from "redux";
import { Wallet } from "../types";

export interface PushNotificationFetchAction extends Action {
    type: TypeKeys.FETCH_PUSH_NOTIFICATION;
}

export interface PushNotificationFetchFulfilledAction extends Action {
    type: TypeKeys.FETCH_PUSH_NOTIFICATION_FULFILLED,
    token: string
}

export interface AddWalletAction extends Action {
    type: TypeKeys.ADD_WALLET;
    balance: number
}

export const addWallet = (balance: number): AddWalletAction => ({
    type: TypeKeys.ADD_WALLET,
    balance
});

export const pushNotification = (): PushNotificationFetchAction => ({
    type: TypeKeys.FETCH_PUSH_NOTIFICATION,
});
export const pushNotificationFulfilled = (token: string): PushNotificationFetchFulfilledAction => ({
    type: TypeKeys.FETCH_PUSH_NOTIFICATION_FULFILLED,
    token
});