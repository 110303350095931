import { combineReducers } from 'redux'
import user, * as fromUser from './user'
import wallet, * as fromWallet from './wallet'
import rewards, * as fromRewards from './reward'
import retail, * as fromRetail from './retail'
import history, * as fromHistory from './history'
import notification, * as fromNotification from './notification'
import redeem, * as fromRedeem from './redeem'
import { reducer as formReducer } from 'redux-form'
import { User, Wallet, State, Reward, Retail, PointHistory, Notification, Redeem } from '../types';

const reducers = combineReducers<State>({
    user,
    wallet,
    rewards,
    retail,
    history,
    notification,
    redeem,
    form: formReducer
})

export const getUser = (state: State): User => fromUser.getUser(state)
export const getWallet = (state: State): Wallet => fromWallet.getWallet(state.wallet)
export const getRewards = (state: State): Reward[] => fromRewards.getRewards(state.rewards)
export const getReward = (state: State, rewardId: string): Reward | undefined => fromRewards.getReward(state.rewards, rewardId)
export const getRetail = (state: State): Retail => fromRetail.getRetail(state.retail)
export const getHistory = (state: State): PointHistory[] => fromHistory.getHistory(state.history)
export const getNotification = (state: State): Notification => fromNotification.getNotification(state.notification)
export const getRedeems = (state: State): Redeem[] => {
    return fromRedeem.getRedeems(state.redeem).map(redeem => ({
        reward: getReward(state, redeem.rewardId)!,
        id: redeem.id,
        cost: redeem.cost && redeem.cost,
        awarded: redeem.awarded && redeem.awarded,
        createdAt: redeem.createdAt && redeem.createdAt
    }))
}


export default reducers