import React, { Dispatch } from 'react';
import { StyleSheet, FlatList, View, Text, ScrollView } from 'react-native';
import { Theme } from '../styles';
import { State, PointHistory, User } from '../types';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PointHistoryItem from '../components/PointsHistoryItem';
import { getHistory, getUser } from '../reducers';
import { getFetchHistory } from '../actions';
import ListEmpty from '../components/ListEmpty';
import { NavigationScreenProp } from 'react-navigation';
import NavigationConstants from '../navigators/NavigationConstants';


export interface PointsHistoryProps {
    history: PointHistory[],
    getFetchHistory: any,
    user: User,
    navigation: NavigationScreenProp<any, any>
};

export interface PointsHistoryForm {

};

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        backgroundColor: Theme.color.white,
        height: '100%'
    },
    points: {
        flexGrow: 1
    },
    title: {
        fontFamily: 'roboto',
        color: Theme.color.black87,
        fontSize: 20,
        marginTop: 15,
        marginBottom: 15,
        marginLeft: 15,
        marginRight: 15,
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    welcomeContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Theme.color.white
    },
    welcomeText: {
        marginLeft: 5,
        marginRight: 5,
        marginTop: 35,
        fontSize: 20,
        color: Theme.color.black54,
        textAlign: 'center'
    },
    pointsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    }
});

class PointsHistory extends React.Component<InjectedFormProps<PointsHistoryForm, PointsHistoryProps> & PointsHistoryProps> {

    static navigationOptions = {
        title: 'History',
        headerBackStyleColor: Theme.color.black,
        headerTintColor: Theme.color.white,
        headerStyle: {
            backgroundColor: Theme.color.black,
        },
        titleStyle: {
            textAlign: 'left'
        },
        headerTitleStyle: {
            fontSize: 24,
            fontWeight: '600',

            flex: 1,
            justifyContent: 'center',
            alignItems: 'flex-start'
        }
    };

    render() {

        const { history, user } = this.props

        if (!user) {
            const { navigate } = this.props.navigation;
            navigate(NavigationConstants.LOGIN)
            return null
        }

        return (
            <ScrollView contentContainerStyle={styles.container}>
                <FlatList contentContainerStyle={styles.points}
                    ListHeaderComponent={
                        <View style={styles.pointsContainer}>
                            <Text style={styles.title}>{"Date"}</Text>
                            <Text style={styles.title}>{'Points received'}</Text>
                        </View>
                    }
                    data={history}
                    ListEmptyComponent={
                        <ListEmpty text={'Here you will see points history after purchases'}
                            icon={'star'} />
                    }
                    scrollEnabled={false}
                    keyExtractor={(_item, index) => `${index}`}
                    renderItem={({ item }) => <PointHistoryItem item={item} />} />
            </ScrollView>
        );
    }
}

const mapStateToProps = (state: State) => {
    return {
        user: getUser(state),
        history: getHistory(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<PointsHistoryForm>) => {
    return {
        getFetchHistory: (id: string) => dispatch(getFetchHistory(id)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm<PointsHistoryForm, PointsHistoryProps>({
    form: 'PointsHistory'
})(PointsHistory))