import { LoginAction, OtherAction, LoginFulfilledAction, RegisterAction, RegisterFulfilledAction, LogoutAction, ResetPasswordAction, ResetPasswordFulfilledAction, SyncAction, SyncFulfilledAction, LogoutFulfilledAction, SaveUserAction, SaveUserFulfilledAction, syncFailedAction, SendEmailAction, SendEmailFulfilledAction, LoginStartAction, ResendEmailAction, LoginFailedAction, } from "./auth";
import { HistoryFetchAction, HistoryFetchFulfilledAction } from "./history";
import { PushNotificationFetchAction, PushNotificationFetchFulfilledAction, AddWalletAction } from "./notification";
import { RedeemRewardAction, RewardRedeemFulfilledAction, FetchRedeemAction, FetchRedeemFulfilledAction } from "./redeem";

export * from "./auth";
export * from "./history"
export * from "./notification"
export * from "./redeem"

export type ActionTypes =
    | LoginAction
    | LoginFulfilledAction
    | OtherAction
    | RegisterAction
    | RegisterFulfilledAction
    | LogoutAction
    | LogoutFulfilledAction
    | syncFailedAction
    | ResetPasswordAction
    | ResetPasswordFulfilledAction
    | SyncAction
    | SyncFulfilledAction
    | SaveUserAction
    | SaveUserFulfilledAction
    | HistoryFetchAction
    | HistoryFetchFulfilledAction
    | PushNotificationFetchAction
    | PushNotificationFetchFulfilledAction
    | RedeemRewardAction
    | RewardRedeemFulfilledAction
    | FetchRedeemAction
    | FetchRedeemFulfilledAction
    | AddWalletAction
    | SendEmailAction
    | SendEmailFulfilledAction
    | LoginStartAction
    | ResendEmailAction
    | LoginFailedAction