export enum TypeKeys {
  LOGIN = "LOGIN",
  LOGIN_START = "LOGIN_START",
  LOG_OUT = "LOG_OUT",
  SYNC_FAILED = "SYNC_FAILED",
  LOG_OUT_FULFILLED = "LOG_OUT_FULFILLED",
  RESET_PASSWORD = "RESET_PASSWORD",
  REGISTER = "REGISTER",
  LOGIN_FULFILLED = "LOGIN_FULFILLED",
  LOGIN_FAILED= "LOGIN_FAILED",
  SYNC = 'SYNC',
  SAVE_USER = 'SAVE_USER',
  SAVE_USER_FULFILLED = 'SAVE_USER_FULFILLED',
  SYNC_FULFILLED = 'SYNC_FULFILLED',
  REGISTER_FULFILLED = "REGISTER_FULFILLED",
  RESET_PASSWORD_FULFILLED = "RESET_PASSWORD_FULFILLED",
  OTHER_ACTION = "__any_other_action_type__",

  FETCH_HISTORY = 'FETCH_HISTORY',
  FETCH_HISTORY_FULFILLED = 'FETCH_HISTORY_FULFILLED',

  FETCH_PUSH_NOTIFICATION = 'FETCH_PUSH_NOTIFICATION',
  FETCH_PUSH_NOTIFICATION_FULFILLED = 'FETCH_PUSH_NOTIFICATION_FULFILLED',

  ADD_REDEEM = 'FETCH_ADD_REDEEM',
  REWARD_REDEEM_FULFILLED = 'FETCH_ADD_REDEEM_FULFILLED',

  FETCH_REDEEM = 'FETCH_REDEEM',
  FETCH_REDEEM_FULFILLED = 'FETCH_REDEEM_FULFILLED',

  ADD_WALLET = 'ADD_WALLET',

  RESEND_EMAIL = "RESEND_EMAIL",
  SEND_EMAIL = "SEND_EMAIL",
  SEND_EMAIL_FULFILLED = "SEND_EMAIL_FULFILLED",
}