import { TypeKeys } from "../constants/TypeKeys";
import { ActionTypes } from "../actions";
import { RedeemState } from "../types";

const redeem = (state: RedeemState[] = [], action: ActionTypes): RedeemState[] => {
    switch (action.type) {
        case TypeKeys.LOGIN_FULFILLED:
        case TypeKeys.REGISTER_FULFILLED:
        case TypeKeys.SYNC_FULFILLED:
            const { redeems } = action
            return [...redeems]
        case TypeKeys.LOG_OUT_FULFILLED:
            return []
        case TypeKeys.REWARD_REDEEM_FULFILLED:
            return [
                ...state,
                { ...action.redeem }
            ]
        default:
            return state
    }
}

export const getRedeems = (state: RedeemState[]): RedeemState[] => state

export default redeem